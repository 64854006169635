import { ContactInfo } from 'lib/booking-helpers';
import { OppositeLanguage } from 'src/pages/api/editing/data/OppositeLanguage';

//Dorm store
export type RoomBox = {
  Availability: number;
  CurrencyPrefix: never;
  FormattedPriceString: never;
  Price: number;
  Qualifier: string;
  RateCode: string;
  RoomType: string;
  RoomTypeDesc: string;
  RoomTypeLongDesc: string;
  NumberOfPersons: number;
  UniqueQualifier: string;
};

export type PackageBox = {
  ChildPackage: null;
  OriginalPackageCode: string;
  PackageCode: string;
  PackageName: string;
  PackagePrice: number;
  PackagePriceChildCategory: string | undefined;
  PackageWebDescription: string;
  Count?: number;
  Active?: boolean;
};

export const defaultPackageBox = {
  ChildPackage: null,
  OriginalPackageCode: '',
  PackageCode: '',
  PackageName: '',
  PackagePrice: 0,
  PackagePriceChildCategory: '',
  PackageWebDescription: '',
};

export const defaultExtraPackage2Details = {
  adult: {
    PackageCode: '',
    OriginalPackageCode: '',
    PackageName: '',
    PackagePrice: 0,
    PackageWebDescription: '',
    PackagePriceChildCategory: '',
    ChildPackage: null,
  },
  child: {
    PackageCode: '',
    OriginalPackageCode: '',
    PackageName: '',
    PackagePrice: 0,
    PackageWebDescription: '',
    PackagePriceChildCategory: '',
    ChildPackage: null,
  },
};

export type PricingStructure = {
  Dorms: {
    B4: {
      [key: string]: RoomBox[];
    };
    B6: {
      [key: string]: RoomBox[];
    };
  };
  Offers: { [key: string]: RoomBox[] };
  Rooms: { [key: string]: RoomBox[] };
  FirstAvailableRatecode: string;
};

export interface Dorm {
  Corporate: PricingStructure;
  Flex: PricingStructure;
  Packages: PackageBox[];
  Restricted: PricingStructure;
}

export type DormState = {
  DormsData?: Dorm;
  setDormsData: (DormsData: Dorm) => void;
  DormRooms?: PricingStructure;
  setDormRooms: (DormRooms: PricingStructure) => void;
  soldoutDorms: string[];
  setSoldoutDorms: (soldoutDorms: string[]) => void;
};

type RoomBoxWithGuests = RoomBox & { numberOfGuests: number };

//Booking menu store
export type BookingMenuStore = {
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  datePickerOpen: boolean;
  setDatePickerOpen: (value: boolean) => void;
  roomPickerOpen: boolean;
  setRoomPickerOpen: (value: boolean) => void;
  errorStepOpen: boolean;
  setErrorStepOpen: (value: boolean) => void;
  paymentStepOpen: boolean;
  setPaymentStepOpen: (value: boolean) => void;
  changeStepOpen: boolean;
  setChangeStepOpen: (value: boolean) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
};

export type MenuDatesStore = {
  menuFetchingDates: { checkIn: Date | null; checkOut: Date | null };
  setMenuFetchingDates: (date: { checkIn: Date | null; checkOut: Date | null }) => void;
  oldFetchingDates: { checkIn: Date | null; checkOut: Date | null };
  setOldFetchingDates: (date: { checkIn: Date | null; checkOut: Date | null }) => void;
  flex: boolean;
  setFlex: (value: boolean) => void;
  isFlexDisabled: boolean;
  setIsFlexDisabled: (value: boolean) => void;
  isRestrictedDisabled: boolean;
  setIsRestrictedDisabled: (value: boolean) => void;
  // ONG-3887 function to handle google links with quick search
  trackingDates: { checkIn: Date | null; checkOut: Date | null };
  setTrackingDates: (date: { checkIn: Date | null; checkOut: Date | null }) => void;
};

//Cart store
export type CartItemFields = {
  category: string;
  identifier: number;
  packages: Array<PackageBox> | [];
  notCorresponding: boolean;
};

export type CartItem = RoomBoxWithGuests & CartItemFields;

//used for sending reservations to PrivateChildModal where they will be looped and assigned identifier
export type Reservations = RoomBoxWithGuests & {
  category: string;
  packages: Array<PackageBox> | [];
  notCorresponding: boolean;
};

export type ExtraPackage2 = {
  numAdults: number;
  numChildren: number;
  savedExtraPackage2Option?: string;
};
export type ExtraPackage2Details = {
  adult: PackageBox;
  child: PackageBox;
};

export type CartStore = {
  identifier: number;
  cartItemsIds: Array<string>;
  setCartItemIds: (cartItemIds: Array<string>) => void;
  addCartItemId: (id: string) => void;
  removeCartId: (id: string) => void;
  cartItems: Array<CartItem>;
  package1DetailsList: Array<PackageBox>;
  setCartItems: (cartItems: Array<CartItem>) => void;
  addCartItem: (cartItem: CartItem) => void;
  addExtraPackage1Details: (package1Details: PackageBox) => void;
  setExtraPackage1DetailsActive: (package1Code: string, newState: boolean) => void;
  setAllExtraPackage1DetailsNotActive: () => void;
  updateCartItemGuests: (id: string, incrementor: number) => void;
  removeCartItem: (id: number) => void;
  cartFetchingDates: { checkIn: Date | null; checkOut: Date | null };
  setCartFetchingDates: (date: { checkIn: Date | null; checkOut: Date | null }) => void;
  flexCart: boolean;
  setFlexCart: (value: boolean) => void;
  //ExtraPackages
  setExtraPackageActive: (extraPackageDetails: PackageBox) => void;
  extraPackage2Details: ExtraPackage2Details;
  setExtraPackage2Details: (value: ExtraPackage2Details) => void;
  extraPackage2Checked: boolean;
  setExtraPackage2Checked: (value: boolean) => void;
  clearExtraPackage: (packageCode: string) => void;
  updateCartItemPackage2: (
    identifier: number,
    packageCode1: string,
    numAdults: number,
    packageCode2: string,
    numChildren: number
  ) => void;
  clearExtraPackageChildrenSelect: (identifier: number, packageCode: string) => void;
  setExtraPackageChildrenSelectActive: (
    identifier: number,
    numChildren: number,
    extraPackageDetails: PackageBox
  ) => void;
  //company info
  companyRatesActive: boolean;
  setCompanyRatesActive: (value: boolean) => void;
};

//Currency store
export type SupportedCurrencyKeys = 'DKK' | 'SEK' | 'EUR' | 'NOK' | 'USD' | 'GBP';

export type CurrencyObjectItem = {
  Code: SupportedCurrencyKeys;
  Postfix: string;
  Prefix: string;
  Rate: number;
  Symbol: null;
  Title: string;
  Format: string;
};

export type CurrencyObject = {
  [key in SupportedCurrencyKeys]: CurrencyObjectItem;
};

export const staticExchangeRates: CurrencyObject = {
  DKK: {
    Code: 'DKK',
    Postfix: ',-',
    Prefix: 'DKK',
    Rate: 100.0,
    Symbol: null,
    Title: 'DKK',
    Format: '{0:0}',
  },
  SEK: {
    Code: 'SEK',
    Postfix: ',-',
    Prefix: 'SEK',
    Rate: 68.2,
    Symbol: null,
    Title: 'SEK',
    Format: '{0:0}',
  },
  EUR: {
    Code: 'EUR',
    Postfix: '',
    Prefix: '€',
    Rate: 743.82,
    Symbol: null,
    Title: 'EUR',
    Format: '{0:0.00}',
  },
  NOK: {
    Code: 'NOK',
    Postfix: ',-',
    Prefix: 'NOK',
    Rate: 70.92,
    Symbol: null,
    Title: 'NOK',
    Format: '{0:0}',
  },
  USD: {
    Code: 'USD',
    Postfix: '',
    Prefix: '$',
    Rate: 707.12,
    Symbol: null,
    Title: 'USD',
    Format: '{0:0.00}',
  },
  GBP: {
    Code: 'GBP',
    Postfix: '',
    Prefix: '£',
    Rate: 862.32,
    Symbol: null,
    Title: 'GBP',
    Format: '{0:0.00}',
  },
};

export type CurrencyStore = {
  currentCurrency: CurrencyObjectItem;
  setCurrentCurrency: (currency: SupportedCurrencyKeys) => void;
  currencyObject: CurrencyObject;
  setCurrencyObject: (currencyObject: CurrencyObject) => void;
};

export type OppositeLanguageStore = {
  currentOppositeLanguage: OppositeLanguage;
  setCurrentOppositeLanguage: (language: OppositeLanguage) => void;
};

export const companyDetailsConst = {
  City: '',
  CountryIso2: '',
  Email: '',
  GuestFirstname: '',
  GuestName: '',
  NationalityIso2: '',
  ProfileCategory: '',
  ProfileId: '0',
  ProfileType: '',
  Street1: '',
  Telephone: '',
  Title: '',
  ZipCode: '',
  ErrorText: '',
  Status: 0,
};

//Company store
export type CompanyStore = {
  companyActive: boolean;
  setCompanyActive: (currency: boolean) => void;
  loggedIn: boolean;
  setLoggedIn: (value: boolean) => void;
  companyDetails: typeof companyDetailsConst;
  setCompanyDetails: (value: typeof companyDetailsConst) => void;
  firstAvailableRateCode: string;
  setFirstAvailableRateCode: (value: string) => void;
};

//ContactForm store
export type ContactFormStore = {
  agreeTerms: boolean;
  setAgreeTerms: (value: boolean) => void;
  agreeError: boolean;
  setAgreeError: (value: boolean) => void;
  paymentLoading: boolean;
  setPaymentLoading: (value: boolean) => void;
  newsletterChecked: boolean;
  setNewsletterChecked: (value: boolean) => void;
};

export const sitecoreFields = {
  newsletterShow: false,
  newsletterChecked: false,
  newsletterTerms: '',
  paymentTerms: '',
  thankYouPage: '',
  errorPage: '',
  companyRates: '',
  companyRatesPublic: '',
  DisableMessageDormsPrivate: false,
  DisableMessagePrivate: false,
  BlockOrder: '',
};

export type SitecoreFieldsStore = {
  sitecoreFields: typeof sitecoreFields;
  setSitecoreFields: (value: typeof sitecoreFields) => void;
};

export type cancelPackage = {
  code: string;
  frequencyCode: string;
  frequencyName: string;
  name: string;
  price: number;
};

export interface CancelReservationData {
  cancelDeadline: string;
  lengthOfStay: number;
  totalAmount: number;
  channelCode: string;
  companyId: string;
  companyName: string;
  email: string;
  guestArrival: string;
  guestDeparture: string;
  guestFirstname: string;
  guestName: string;
  guestNum: string;
  marketCode: string;
  noOfAdults: string;
  noOfChildren: string;
  noOfRooms: string;
  packages: cancelPackage[];
  profileId: string;
  rateCode: string;
  rateFeatures: number;
  rateValue: string;
  roomType: string;
  roomTypeDesc: string;
  sourceCode: string;
  accompanyingGuest: string[];
  bookerName: string;
  totalStay: number;
  errorText: string;
  status: number;
  roomPrice: number;
}

export type CancelExtraPackage1 = { code: string; name: string; price: number };
export type CancelExtraPackage2 = { price: number; numAdults: number; numChildren: number };

export type CancelData = {
  firstName: string;
  lastName: string;
  email: string;
  guestNum: string;
  roomType: string;
  dates: {
    checkIn: string;
    checkOut: string;
  };
  numberOfGuests: number;
  totalPrice: number;
  roomPrice: number;
  extraPackages1?: CancelExtraPackage1[];
  extraPackage2?: CancelExtraPackage2;
};

export type ChangeBookingStore = {
  changeReservationDetails: CancelReservationData | null;
  setChangeReservationDetails: (value: CancelReservationData) => void;
  datePickerChangeActive: boolean;
  setDatePickerChangeActive: (value: boolean) => void;
  changeReservationDates: { checkIn: Date | null; checkOut: Date | null };
  setChangeReservationDates: (date: { checkIn: Date | null; checkOut: Date | null }) => void;
};

export type HostelCreatorStore = {
  hostelVariant: string;
  setHostelVariant: (value: string) => void;
};

//general store
export type GeneralStore = {
  loaded: boolean;
  setLoaded: (value: boolean) => void;
  isDesktop: boolean;
  setIsDesktop: (value: boolean) => void;
  maintenanceActive: boolean;
  setMaintenanceActive: (value: boolean) => void;
};

export type CustomerDataStore = {
  customerData: ContactInfo;
  setCustomerData: (value: ContactInfo) => void;
};
