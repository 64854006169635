import { format } from 'date-fns';
import { CartItem, CurrencyObjectItem, PricingStructure, RoomBox } from 'src/store/booking-store-modules';
import { enGB, da } from 'date-fns/locale';

export const soldoutDorm = {
  '4BED-SOLDOUT': {
    Availability: 0,
    CurrencyPrefix: null,
    FormattedPriceString: null,
    Price: 0,
    Qualifier: '0',
    RateCode: '',
    RoomType: '4BED-SOLDOUT',
    RoomTypeDesc: '',
    RoomTypeLongDesc: '',
    NumberOfPersons: 0,
  } as RoomBox,
  '6BED-SOLDOUT': {
    Availability: 0,
    CurrencyPrefix: null,
    FormattedPriceString: null,
    Price: 0,
    Qualifier: '0',
    RateCode: '',
    RoomType: '6BED-SOLDOUT',
    RoomTypeDesc: '',
    RoomTypeLongDesc: '',
    NumberOfPersons: 0,
  } as RoomBox,
};

export const generateOptions = (limit: number): JSX.Element[] => {
  const roomsOptions = [];
  for (let i = 1; i <= limit; i++) {
    roomsOptions.push(
      <option value={i} key={i}>
        {i}
      </option>
    );
  }
  return roomsOptions;
};

export const findBookedAvailability = (cartItems: CartItem[], RoomType: string, cartItemsIds?: string[]): number => {
  let bookedGuests = 0;
  if (cartItemsIds && RoomType.includes('B-') && cartItemsIds.includes(RoomType)) {
    const roomInCart = cartItems.find((item) => item.RoomType == RoomType);
    bookedGuests = roomInCart?.numberOfGuests || 0;
  } else {
    bookedGuests = cartItems.filter((item) => item.RoomType == RoomType).length;
  }

  return bookedGuests;
};

//check if Room is b-4,b-4f,b-6,b-6f, 4bed, 6bed
export const checkRoomType = (currentRoomType: string, ...roomTypes: string[]): boolean => {
  return roomTypes.includes(currentRoomType);
};

export const checkLimiter = (roomType: string, suite8Availability: number, sitecoreAvailability: number): number => {
  switch (roomType) {
    case '4BED':
      return 4;
    case '6BED':
      return 6;
    case 'B-4':
    case 'B-4F':
    case 'B-6':
    case 'B-6F':
      return suite8Availability < sitecoreAvailability ? suite8Availability : sitecoreAvailability;
    default:
      return 0;
  }
};

//check dormbox soldout
//DormData is flattened into an array with two object {B4, B4F, 4BED}, {B6, B6F, 6BED} - dormValues.
//we then loop over dormValues, transform each into Array, and loop over each array (dorm) to check if Availability is <= 0
export const checkDormBoxSoldOut = (DormData: PricingStructure['Dorms']) => {
  const soldoutDorms: string[] = [];
  const dormValues = Object.values(DormData);

  dormValues.forEach((dorm) => {
    Object.values(dorm).forEach((dormDetail) => {
      dormDetail[0].Availability <= 0 && soldoutDorms.push(dormDetail[0].RoomType);
    });
  });

  return soldoutDorms;
};

type DormData = PricingStructure['Dorms']['B4'] | PricingStructure['Dorms']['B6'];

export const checkDormRoomDisabled = (DormData: DormData, roomType: string, soldoutDorms: string[]) => {
  return roomType in DormData && soldoutDorms.includes(roomType);
};

export const getAlertMessage = (alertName: string): Record<string, string> => {
  switch (alertName) {
    case 'childNotAllowed':
      return { title: 'booking_alert_header', message: 'booking_alert_message' };
    case 'datesNotEqual':
      return { title: 'booking_dates_not_equal_header', message: 'booking_dates_not_equal_message' };
    case 'companyRates':
      return { title: 'booking_company_rates_header', message: 'booking_company_rates_message' };
    default:
      return { title: 'booking_error_tagline', message: 'booking_error_tagline' };
  }
};

const locales = {
  da,
  en: enGB,
};

export const shortDateFormat = (date: string | Date, locale: string): string => {
  return format(new Date(date), 'EE d MMM', {
    locale: locales[locale as keyof typeof locales],
  });
};
export const longDayDateFormat = (date: string | Date, locale: string): string => {
  return format(new Date(date), 'EE d MMMM', {
    locale: locales[locale as keyof typeof locales],
  });
};

//make date format dd.mm.yyyy hh:mm:ss
export const mailDateFormat = (date: string | Date): string => {
  return format(new Date(date), 'dd.MM.yyyy HH:mm:ss');
};
export const shortDateFormatWithYear = (date: string | Date, locale = 'en'): string => {
  return format(new Date(date), 'EE d MMM yyyy', {
    locale: locales[locale as keyof typeof locales],
  });
};

export const shortISODateFormat = (date: string | Date): string => {
  return format(new Date(date), 'yyyy-MM-dd');
};

export const currentCurrencyValue = (value: number, locale: string, currentCurrency: CurrencyObjectItem): string => {
  const currencyValue = value / (currentCurrency.Rate / 100);
  const digits = currentCurrency.Postfix == '' ? 2 : 0;
  const formatter = new Intl.NumberFormat(locale, { maximumFractionDigits: digits });
  const newValue = formatter.format(currencyValue) + currentCurrency.Postfix;
  return newValue;
};

//roomPicker order

export const roomPickerOrderString = 'dorms,rooms,offers,flexibility,extra,contact';

type roomPickerOrderItem = {
  key: string;
  title: string;
  roomBox: boolean;
  roomBoxType: string;
  extraBox: boolean;
};

const validateRoomPickerString = (roomPickerString: string): string => {
  const splittedRoomPickerString = roomPickerString.split(',');

  if (
    splittedRoomPickerString.includes('dorms') &&
    splittedRoomPickerString.includes('rooms') &&
    splittedRoomPickerString.includes('contact') &&
    splittedRoomPickerString.includes('flexibility')
  ) {
    return roomPickerString;
  }
  return roomPickerOrderString;
};

export const generateRoomPickerOrder = (roomPickerOrder: string): roomPickerOrderItem[] => {
  //make sure that roomPickerOrderString contains dorms
  const validatedRoomPickerString = validateRoomPickerString(roomPickerOrder);
  const roomPickerOrderArray = validatedRoomPickerString.split(',');
  const roomPickerOrderArrayWithKeys: roomPickerOrderItem[] = [];

  roomPickerOrderArray.forEach((item) => {
    switch (item) {
      case 'dorms':
        roomPickerOrderArrayWithKeys.push({
          key: 'dorms1',
          title: 'booking_dorms_headline',
          roomBox: true,
          roomBoxType: 'Dorms',
          extraBox: false,
        });
        break;
      case 'rooms':
        roomPickerOrderArrayWithKeys.push({
          key: 'rooms2',
          title: 'booking_rooms_headline',
          roomBox: true,
          roomBoxType: 'Rooms',
          extraBox: false,
        });
        break;
      case 'offers':
        roomPickerOrderArrayWithKeys.push({
          key: 'offers3',
          title: 'booking_offers_headline',
          roomBox: true,
          roomBoxType: 'Offers',
          extraBox: false,
        });
        break;
      case 'contact':
        roomPickerOrderArrayWithKeys.push({
          key: 'contactFormBox',
          title: 'booking_contact_headline',
          roomBox: false,
          roomBoxType: 'Contact',
          extraBox: false,
        });
        break;
      case 'extra':
        roomPickerOrderArrayWithKeys.push({
          key: 'extraBox',
          title: 'booking_extra_headline',
          roomBox: false,
          roomBoxType: 'Extra',
          extraBox: true,
        });
        break;
      case 'flexibility':
        roomPickerOrderArrayWithKeys.push({
          key: 'flexibilityBox',
          title: 'booking_flexibility_headline',
          roomBox: false,
          roomBoxType: 'Flexibility',
          extraBox: false,
        });
        break;
      default:
        break;
    }
  });

  return roomPickerOrderArrayWithKeys;
};
