import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const SearchIcon = (props: IconProps): ReactElement => {
  return (
    <Icon {...props} viewBox="0 0 25 25">
      <g fill="transparent">
        <circle
          cx="11.105"
          cy="11.1055"
          r="9.93085"
          transform="rotate(-45 11.105 11.1055)"
          stroke="#212121"
          strokeWidth="1.5"
        />
        <rect
          x="18.0139"
          y="19.1612"
          width="1.62411"
          height="7.32057"
          transform="rotate(-45 18.0139 19.1612)"
          fill="#212121"
          stroke="#212121"
          strokeWidth="0.2"
        />
      </g>
    </Icon>
  );
};
