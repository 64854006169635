import { Icon } from '@chakra-ui/react';
import { BackArrowIcon } from './BackArrowIcon';
import { CloseIcon } from './CloseIcon';
import { LinkArrowLeftIcon } from './LinkArrowLeftIcon';
import { LinkArrowRightIcon } from './LinkArrowRightIcon';
import { SearchIcon } from './SearchIcon';

const UiIcons = new Map<string, typeof Icon>([
  ['back-arrow', BackArrowIcon],
  ['close', CloseIcon],
  ['link-arrow-left', LinkArrowLeftIcon],
  ['link-arrow-right', LinkArrowRightIcon],
  ['search', SearchIcon],
]);

export default UiIcons;

export * from './BackArrowIcon';
export * from './CloseIcon';
export * from './LinkArrowLeftIcon';
export * from './LinkArrowRightIcon';
export * from './SearchIcon';
