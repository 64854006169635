import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const BackArrowIcon = (props: IconProps): ReactElement => {
  return (
    <Icon {...props} viewBox="0 0 25 25">
      <path d="M8 5C8 9.41828 4.41828 13 -1.19209e-07 13" stroke="currentColor" fill="transparent" strokeWidth="1.3" />
      <path d="M-1.19209e-07 13C4.41828 13 8 16.5817 8 21" fill="transparent" stroke="currentColor" strokeWidth="1.3" />
      <line x1="2" y1="12.95" x2="25" y2="12.95" stroke="currentColor" strokeWidth="1.3" />
    </Icon>
  );
};
