import { Box, Button, ButtonProps, useMultiStyleConfig, Text, Spinner, Flex } from '@chakra-ui/react';
import React from 'react';

type CButtonProps = {
  backgroundColor?: string;
  color?: string;
  text: string;
  loading?: boolean;
  variant?: 'solid' | 'outline';
  type?: 'button' | 'submit' | 'reset';
  editEventCalendar?: boolean;
} & ButtonProps;

// There is an identical module component
// This one is for other components to use

const CButton: React.FC<CButtonProps> = ({
  backgroundColor,
  color,
  text,
  variant,
  loading,
  editEventCalendar,
  ...rest
}) => {
  const styles = useMultiStyleConfig('CTAButton', {});
  variant = variant ?? 'solid';
  loading = loading ?? false;

  return (
    <Button
      __css={{
        ...styles[variant],
        backgroundColor: backgroundColor ?? styles[variant].backgroundColor,
        borderColor: color ?? styles.text.color,
        width: '100%',
        borderRadius: editEventCalendar ? '0px 50px 50px !important' : 'full',
      }}
      {...rest}
    >
      <Box __css={{ overflow: 'hidden', position: 'relative' }}>
        <Box className="text" __css={{ ...styles.text, color: color ?? styles.text.color }}>
          <Flex align={'center'} justify={'center'} gap="5px">
            <Text>{text}</Text>
            {loading && <Spinner size={'sm'} />}
          </Flex>
        </Box>
        <Box className="revert-text" __css={{ ...styles.revertText, color: color ?? styles.text.color }}>
          <Text>{text}</Text>
        </Box>
      </Box>
    </Button>
  );
};

export default CButton;
