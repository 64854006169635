import axios from 'axios';
import { HeadGlobalTrackingJsonValue } from 'src/Component-models/GlobalTrackingQL';
import { HeadPageonlyTracking } from 'src/Component-models/TrackingScripts';
import { CurrencyObjectItem } from 'src/store/booking-store-modules';
import { currentCurrencyValue } from './booking-box-helpers';
import { useLanguageStore } from '../store/index';

export function formatCurrencyValue(value: number, currency: string): string {
  const options = {
    currency,
    maximumFractionDigits: 2,
  };

  return new Intl.NumberFormat(document.documentElement.lang, options).format(value);
}

//linkcreator helpers
type bookNumsObj = {
  Reservations: string[];
  hotelCode: string;
};

interface IGroupLinkResponse {
  ContentEncoding: unknown;
  ContentType: unknown;
  Data: {
    Status: string;
    Data: string;
    Code: number;
    Message: string | null;
  };
  RecursionLimit: unknown;
  StatusCode: number;
}

const hostelCode = process.env.NEXT_PUBLIC_HOSTEL_CODE;

export const validateBookNums = (bookNums: string[]): { error: string | null; bookNumsObj: bookNumsObj | null } => {
  //check if all bookNums are numbers with every
  const allNumbers = bookNums.every((link) => {
    return !isNaN(Number(link));
  });
  if (!allNumbers) {
    return { error: 'Du må kun indtaste tal', bookNumsObj: null };
  }

  // trim all bookNums
  bookNums.forEach((link, index) => {
    bookNums[index] = link.trim();
  });
  // remove unused ','
  bookNums.forEach((link, index) => {
    if (link === '') {
      bookNums.splice(index, 1);
    }
  });
  // if ends on ',' remove it
  if (bookNums[bookNums.length - 1] === '') {
    bookNums.pop();
  }
  // check if all bookNums are unique
  const uniqueBookNums = [...new Set(bookNums)];
  if (uniqueBookNums.length !== bookNums.length) {
    return { error: 'Du må ikke indtaste dobbelt numre', bookNumsObj: null };
  }

  //hostelCode might have to be fixed in .env.local.nex
  if (!hostelCode) {
    return { error: 'Hostel code not set', bookNumsObj: null };
  }

  return { error: null, bookNumsObj: { Reservations: bookNums, hotelCode: hostelCode } };
};

export const generateGroupLink = async (bookNumsObj: bookNumsObj): Promise<IGroupLinkResponse> => {
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  const bookNums = JSON.stringify(bookNumsObj);

  const response = await axios.post('api/ahhg/Zaplox/GenerateGroupLink', bookNums);
  return response.data;
};

export const generateGuestLink = async (resNum: string): Promise<string> => {
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  const trimmedResNum = resNum.trim();

  const response = await axios.post('api/ahhg/Zaplox/GuestRegUrl/' + trimmedResNum);
  return response.data;
};

type TLabels = {
  en: {
    guestReg: string;
    erros: {
      [key: string]: string;
    };
  };
  da: {
    guestReg: string;
    erros: {
      [key: string]: string;
    };
  };
};
//ShowGuestReg
export const labels: TLabels = {
  en: {
    guestReg: 'Guest registration',
    erros: {
      noResNum: 'Please enter a reservation number',
      resNumNotFound: 'Reservation number not found',
    },
  },
  da: {
    guestReg: 'Gæsteregistrering',
    erros: {
      noResNum: 'Indtast venligst et reservation nummer',
      resNumNotFound: 'Reservation nummeret blev ikke fundet',
    },
  },
};

export const fetchReservertionFromGuid = async (guid: string): Promise<IGroupLinkResponse> => {
  const response = await axios.get<IGroupLinkResponse>('api/ahhg/Zaplox/GetReservationFromGuid/' + guid);
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  const reservationNumber = response.data.Data.Data.split(',')[0];
  const bookNumsObj = {
    ReservationNumber: reservationNumber.trim(),
    hotelCode: 'STE',
  };
  const bookNums = JSON.stringify(bookNumsObj);
  axios.post('api/ahhg/Zaplox/FetchHostelReservation/', bookNums).then((res) => {
    console.log(res);
  });

  return response.data;
};

//get rgba from hex
export const hexToRgbA = (hex: string, alpha: number): string => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
  }
  //return black if cannot convert
  return 'rgba(0,0,0, 1)';
};

//get hex from rgb
export const rgbToHex = (red: string, green: string, blue: string) => {
  const rgb = (parseInt(red) << 16) | (parseInt(green) << 8) | (parseInt(blue) << 0);
  return '#' + (0x1000000 + rgb).toString(16).slice(1);
};

//dataLayer
declare const window: Window & { dataLayer: Record<string, unknown>[] };

export type dataLayerObject = {
  item_id: string;
  item_name: string;
  item_variant: string;
  item_brand: string;
  item_category: string;
  item_category2: number;
  item_category3: number;
  item_category4: number;
  item_category5: string;
  item_list_id: string;
  item_list_name: string;
  affiliation: string;
  currency: string;
  price: number;
  quantity: number;
};

export type dataLayerCustomerDataObject = {
  customer_email: string;
  customer_phone: string;
};

type transactionData = {
  transaction_id: string;
  affiliation: string;
  value: number;
  tax: number;
  shipping: number;
  currency: string;
  coupon: string;
};

export const fireEcomDataLayerEvent = (
  event: string,
  data: Array<dataLayerObject>,
  transactionData?: transactionData
): void => {
  window.dataLayer = window.dataLayer || [];
  // window.dataLayer.push({ event: 'clear previous', ecommerce: null }); // Clear the previous ecommerce object.
  //only purchase and begin_checkout should have transactionData
  if (transactionData) {
    window.dataLayer.push({
      event: event,
      ecommerce: {
        ...transactionData,
        items: data,
      },
    });
    return;
  }
  window.dataLayer.push({
    event: event,
    ecommerce: {
      items: data,
    },
  });
};

export const fireCustomDataLayerEvent = (event: string, data: dataLayerCustomerDataObject): void => {
  window.dataLayer = window.dataLayer || [];
  // window.dataLayer.push({ event: 'clear previous', ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: event,
    ...data,
  });
};

export enum dataLayerHostelNames {
  'nex' = 'Next House Copenhagen',
  'ste' = 'Steel House Copenhagen',
}

export enum dataLayerItemNames {
  'B-4' = '4 bed dorm',
  'B-4F' = '4 bed dorm',
  '4BED' = '4 bed dorm',
  'B-6' = '6 bed dorm',
  'B-6F' = '6 bed dorm',
  '6BED' = '6 bed dorm',
  'DBL1' = 'Single room',
  'DBL2' = 'Double room',
  '4BED1' = 'Twin room for 1 person',
  '4BED2' = 'Twin room for 2 persons',
  '4BED3' = 'Twin room for 3 persons',
  '6BED1' = 'Twin room for 1 person',
  '6BED2' = 'Twin room for 2 persons',
  '6BED3' = 'Twin room for 3 persons',
  'WEB' = 'Breakfastbuffet',
  'BUAD' = 'PizzaBuffet',
}

//HostelCreator
export interface HostelCreatorForm {
  title_en: string;
  title_da: string;
  imagePath: string;
  description_en: string;
  description_da: string;
  eventColor: string;
  location_en: string;
  location_da: string;
  link_en: string;
  link_da: string;
  recurringDays: string[];
}

export type EventColor = {
  ColorId: string;
  ColorName: string;
  ColorCode: string;
};

export type MediaImage = {
  ItemId: string;
  ItemName: string;
  ItemPath: string;
  ItemUrl: string;
};

export type HostelCreatorReqObject = {
  HostelName: string;
  Image: string;
  Title: string;
  Description: string;
  CardColor: string;
  Link: string;
  StartDate: string;
  EndDate?: string | null;
  StartTime: string;
  EndTime: string;
  Location: string;
  RecurringDays: (string | number)[] | null;
};

export const weekObj: { [key: number]: string } = {
  0: 'Monday',
  1: 'Tuesday',
  2: 'Wednesday',
  3: 'Thursday',
  4: 'Friday',
  5: 'Saturday',
  6: 'Sunday',
};

export type eventObject = {
  Title: string;
  Image: string;
  Description: string;
  CardColor: string;
  Link: string;
  StartTime: string;
  EndTime: string;
  Location: string;
};

// type requestEventObject = {
//   HostelName: string | null;
//   ItemId: string;
//   ItemName: string;
//   Event: {
//     da: eventObject;
//     en: eventObject;
//   };
// };

// export type hostelEventsWeek = {
//   monday: Array<requestEventObject>;
//   tuesday: Array<requestEventObject>;
//   wednesday: Array<requestEventObject>;
//   thursday: Array<requestEventObject>;
//   friday: Array<requestEventObject>;
//   saturday: Array<requestEventObject>;
//   sunday: Array<requestEventObject>;
// };

export type getSingleEvent = {
  ItemId: string;
  ItemName: string;
  HostelName: string;
};

// export const sitecoreIsoDate = (date: Date): string => {
//   return date.toISOString().replaceAll('-', '').replaceAll(':', '').slice(0, -5) + 'Z';
// };

//eventCalendarItem

export const eventCalendarItemTextColor: { [key: string]: string } = {
  'NEX - Green-Blue': '#000000',
  'NEX - Light Grey': '#000000',
  'NEX - Light Green': '#000000',
  'NEX - Broken Yellow': '#000000',
  'NEX - Light Rosa': '#000000',
  'NEX - Rosa': '#000000',
  White: '#000000',
  'Off-white': '#000000',
  'STE - Yellow': '#000000',
  'STE - Light Brown': '#000000',
  'STE - Light Grey': '#000000',
  Black: '#FFFFFF',
  'NEX - Dark Grey': '#FFFFFF',
  'NEX - Purple': '#FFFFFF',
  'STE - Brown': '#FFFFFF',
  'STE - Dark Brown': '#FFFFFF',
  'STE - Dark Green': '#FFFFFF',
  'STE - Dark Grey': '#FFFFFF',
  'STE - Broken Orange': '#FFFFFF',
};

export const transformCurrency = (
  replaceString: string,
  currentCurrency: CurrencyObjectItem,
  locale: string
): string => {
  let updatedPackageDescription = '';
  //find all occurrences of #string# in replaceString and replace with currencyValue and symbol
  const currencyRegex = /#(.*?)#/g;
  const matches = replaceString.match(currencyRegex);
  if (matches && matches?.length > 0) {
    matches.forEach((match) => {
      const string = match.replace(/#/g, '');
      if (isNaN(Number(string))) {
        updatedPackageDescription = replaceString;
        return;
      }
      const currencyValue = currentCurrencyValue(Number(string), locale, currentCurrency);
      const currencyValueAndSymbol = `${currentCurrency.Prefix} ${currencyValue}`;
      updatedPackageDescription =
        updatedPackageDescription.length > 0
          ? updatedPackageDescription.replace(match, currencyValueAndSymbol)
          : replaceString.replace(match, currencyValueAndSymbol);
    });
  } else {
    updatedPackageDescription = replaceString;
  }

  return updatedPackageDescription;
};

//SC scripts

type vwoScript = {
  script: string;
  id?: string;
  strategy?: string;
};

export function assignScripts(globalScripts: HeadGlobalTrackingJsonValue[], pageScripts: HeadPageonlyTracking[]) {
  const codeScripts: Set<string> = new Set();
  const srcScripts: Set<string> = new Set();
  const schemaScripts: Set<string> = new Set();
  const metaScripts: Set<string> = new Set();
  const vwoScripts: Set<vwoScript> = new Set();

  globalScripts.forEach((script) => {
    if (script?.fields.Minified?.value.includes('type="vwoScript"')) {
      vwoScripts.add({
        script: script?.fields.Minified?.value,
        id: script?.fields.id?.value,
        strategy: script?.fields.strategy?.value,
      });
    } else if (script?.fields.Minified?.value.includes('type="application/javascript"')) {
      codeScripts.add(script?.fields.Minified?.value);
    } else if (script?.fields.Minified?.value.includes('src="')) {
      srcScripts.add(script?.fields.Minified?.value);
    } else if (script?.fields.Minified?.value.includes('type="application/ld+json"')) {
      schemaScripts.add(script?.fields.Minified?.value);
    } else if (script?.fields.Minified?.value.includes('<meta ')) {
      metaScripts.add(script?.fields.Minified?.value);
    } else {
      console.log('script not added', script?.fields.Minified?.value);
    }
  });

  pageScripts.forEach((script) => {
    if (script?.fields.Minified?.value.includes('type="application/javascript"')) {
      codeScripts.add(script?.fields.Minified?.value);
    } else if (script?.fields.Minified?.value.includes('src="')) {
      srcScripts.add(script?.fields.Minified?.value);
    } else if (script?.fields.Minified?.value.includes('type="application/ld+json"')) {
      schemaScripts.add(script?.fields.Minified?.value);
    } else if (script?.fields.Minified?.value.includes('<meta ')) {
      metaScripts.add(script?.fields.Minified?.value);
    } else {
      console.log('script not added', script?.fields.Minified?.value);
    }
  });

  return {
    codeScripts: [...codeScripts].map((script) =>
      script
        .replace(/<script[^>]*>/g, '')
        .replace(/<\/script>/g, '')
        .trim()
    ),
    vwoScripts: [...vwoScripts].map((vwoScript) => {
      return {
        script: vwoScript.script
          .replace(/<script[^>]*>/g, '')
          .replace(/<\/script>/g, '')
          .trim(),
        id: vwoScript.id,
        strategy: vwoScript.strategy,
      };
    }),
    srcScripts: [...srcScripts].map((script) => {
      const src = script.match(/src="([^"]*)"/);
      return {
        //add the url inside the src="" to src key
        src: src ? src[1] : '',
      };
    }),
    schemaScripts: [...schemaScripts],
    metaScripts: [...metaScripts],
  };
}

export type orginalDigizuiteImageArray = {
  value: orginalBaseAsset[];
};
export type childrenFields = {
  Image: {
    value: orginalBaseAsset;
  };
  ImageDescription?: {
    value: string;
  };
};
export type orginalDigizuiteImage = {
  fields: childrenFields;
  value: orginalBaseAsset;
};
export type orginalBaseAsset = {
  alt: string | undefined;
  assetTypeId: number;
  title: string;
  src: string;
  src_download: string;
  Photographer: string;
  Source: string;
  Alt_DA: string;
  Alt_EN: string;
};

export type DigizuiteImage = {
  Title: string;
  Src: string;
  Alt: string;
};

export const GetMetaData = (image: orginalBaseAsset | orginalDigizuiteImage | undefined): DigizuiteImage => {
  const currentLanguage = useLanguageStore((state) => state.currentLanguage);
  // if sticker or undefined return empty object
  if (image === undefined)
    return {
      Title: '',
      Src: '',
      Alt: '',
    };
  // if type is "orginalDigizuiteImage" then we need to get the value from the object.
  let finalImage = null;
  if ('value' in image) {
    finalImage = image.value;
  } else {
    finalImage = image;
  }
  if (!finalImage || finalImage.src === '') {
    return {
      Title: '',
      Src: '',
      Alt: '',
    };
  }

  const source = finalImage.Source ? JSON.parse(finalImage.Source) : {};
  const optionValue = source.optionValue ?? '';

  const _title = GetTitle(finalImage.Photographer, optionValue);
  const altText = currentLanguage === 'da' ? finalImage.Alt_DA : finalImage.Alt_EN;

  return {
    Title: _title ?? '',
    Src: finalImage.src ?? '',
    Alt: altText ?? '',
  };
};

// final url.
// <img src="/dfsmedia/baeefe6b74df44be8a2bccfb2c57af8e/831-source/nex-facade-marts-2022"
// alt="Facaden af Next House Copenhagen belagt med kobber" title="Claus Isling | Arp-Hansen Hotel Group">
export const GetTitle = (Photographer: string, Source: string): string => {
  if (!Photographer && !Source) {
    return '';
  }
  return `${Photographer} | ${Source}`;
};

// make an Updated imageFromBreakpoints function.
// takes image, height and width and generates a new image url:
// Orginal url: '/dfsmedia/baeefe6b74df44be8a2bccfb2c57af8e/{imageId}-source/{title}'
// New url:     '/dfsmedia/baeefe6b74df44be8a2bccfb2c57af8e/{imageId}-source/cropsize/{cw}x{ch}/outputimageformat/AvifImageFormat'
export type Breakpoints = { cw: number; ch: number } | undefined;
export const GetImageUrlFromObject = (
  image: orginalBaseAsset | orginalDigizuiteImage | undefined,
  Breakpoints?: Breakpoints
): string => {
  if (!image) {
    return '';
  }
  // Convert orginalDigizuiteImage to orginalBaseAsset if necessary
  const imageObject: orginalBaseAsset = 'value' in image ? image.value : image;
  let imageUrl = `/dfsmedia/baeefe6b74df44be8a2bccfb2c57af8e/${imageObject.src.split('/')[3]}`;
  if (Breakpoints) {
    imageUrl += `/cropsize/${Breakpoints.cw}x${Breakpoints.ch}`;
  }
  imageUrl += '/outputimageformat/AvifImageFormat';
  return process.env.PUBLIC_URL + imageUrl;
};
export const GetImageUrlFromString = (image: string | undefined, breakpoints?: Breakpoints): string => {
  if (!image) {
    return '';
  }
  let imageUrl = `/dfsmedia/baeefe6b74df44be8a2bccfb2c57af8e/${image.split('/')[3]}`;
  if (breakpoints) {
    imageUrl += `/cropsize/${breakpoints.cw}x${breakpoints.ch}`;
  }
  imageUrl += '/outputimageformat/AvifImageFormat';
  return process.env.PUBLIC_URL + imageUrl;
};

// Master function which imageBreakpoints from Json and returns Chakra breakpoints
export const generateBreakpointValues = (jsonObject: {
  [key: string]: { cw?: number; ch?: number; size?: number; renderingId?: string; cx?: number; cy?: number };
}): { [key: string]: Breakpoints } => {
  const breakpointValues: { [key: string]: Breakpoints } = {};

  Object.keys(jsonObject).forEach((key) => {
    const { cw, ch } = jsonObject[key];
    if (cw !== undefined && ch !== undefined) {
      breakpointValues[key] = { cw, ch };
    } else {
      breakpointValues[key] = { cw: 0, ch: 0 };
    }
  });

  return breakpointValues;
};
