import { Box, FormControl, FormLabel, Input } from '@chakra-ui/react';
import CButton from 'components/buttons/CButton';
import React, { ReactElement } from 'react';

interface IProps {
  submitHandler: (e: React.FormEvent<HTMLFormElement>) => void;
}

const HostelEventLogin: React.FC<IProps> = ({ submitHandler }): ReactElement => {
  return (
    <Box>
      <form onSubmit={submitHandler}>
        <FormControl variant="floating">
          <Input id="username" placeholder={'Username'} />
          <FormLabel>{'Username'}</FormLabel>
        </FormControl>
        <FormControl variant="floating" marginBottom="2rem">
          <Input id="password" placeholder={'Password'} type="password" />
          <FormLabel>{'Password'}</FormLabel>
        </FormControl>
        <CButton type="submit" text={'Login'} />
      </form>
    </Box>
  );
};
export default HostelEventLogin;
