import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Spinner,
  useMultiStyleConfig,
  Heading,
  Textarea,
} from '@chakra-ui/react';
import React, { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { addDays, addMonths, format, setHours, setMinutes, isBefore } from 'date-fns';
import da from 'date-fns/locale/da';
import { EventColor, HostelCreatorForm, MediaImage } from 'lib/helpers';
import { useHostelCreatorStore } from 'src/store';
import {
  allowedColorsNex,
  allowedColorsSte,
  HostelEvent,
  HostelEventCulture,
  sitecoreEndIsoDate,
  sitecoreStartIsoDate,
} from './helpers';
import HostelEventEdit from './HostelEventEdit';
import { NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import { CloseIcon } from 'assets/Icons/ui';
import CButton from 'components/buttons/CButton';
import axios from 'axios';

interface IProps {
  eventToEdit?: HostelEvent;
}

const HostelEventCreator: React.FC<IProps> = ({ eventToEdit }): ReactElement => {
  const [startTime, setStartTime] = useState<Date>(new Date());
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date | null>();
  const [recurringChecked, setRecurringChecked] = useState(false);
  const [eventColors, setEventColors] = useState<EventColor[]>([]);
  const [currentColor, setCurrentColor] = useState<string | null>(null);
  const [imagePaths, setImagePaths] = useState<MediaImage[]>([]);
  const [currentImagePath, setCurrentImagePath] = useState<string | null>(null);
  const { hostelVariant } = useHostelCreatorStore((state) => state);
  const [eventCreated, setEventCreated] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [recurringDays, setRecurringDays] = useState<(string | number)[]>([]);

  const onChangeStartDate = (date: Date | null) => {
    if (date === null) return;
    setStartDate(date);
    if (recurringChecked && endDate && isBefore(endDate, date)) {
      setEndDate(addDays(date, 1));
    }
  };

  const onChangeRecurring = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRecurringChecked(e.target.checked);
    e.target.checked ? setEndDate(addDays(startDate, 1)) : setEndDate(null);
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<HostelCreatorForm>({
    mode: 'all',
    defaultValues: {
      title_en: '',
      title_da: '',
      description_en: '',
      description_da: '',
      imagePath: '',
      eventColor: '',
      location_da: '',
      location_en: '',
      link_en: '',
      link_da: '',
      recurringDays: [],
    },
  });

  async function onSubmit(values: HostelCreatorForm) {
    if (recurringChecked && recurringDays.length === 0) {
      alert('Please select at least one day for recurring events.');
      return;
    }
    if (values.title_en.includes('_') || values.title_da.includes('_')) {
      alert('Title must not contain _');
      return;
    }
    //just to be sure
    if (recurringChecked && endDate === null) return;
    try {
      const reqObjectEN: HostelEventCulture = {
        Title: values.title_en,
        Description: values.description_en,
        Location: values.location_en,
        StartTime: format(new Date(startTime), 'HH:mm'),
        EndTime: endTime ? format(new Date(endTime), 'HH:mm') : '',
        CardColor: values.eventColor.split('|')[1],
        CardColorCode: values.eventColor.split('|')[0],
        Image: values.imagePath.split(' ')[0],
        ImageId: values.imagePath.split(' ')[1],
        Link: values.link_en,
      };
      const reqObjectDA: HostelEventCulture = {
        Title: values.title_da.length ? values.title_da : values.title_en,
        Description: values.description_da.length ? values.description_da : values.description_en,
        Location: values.location_da.length ? values.location_da : values.location_en,
        StartTime: format(new Date(startTime), 'HH:mm'),
        EndTime: endTime ? format(new Date(endTime), 'HH:mm') : '',
        CardColor: values.eventColor.split('|')[1],
        CardColorCode: values.eventColor.split('|')[0],
        Image: values.imagePath.split(' ')[0],
        ImageId: values.imagePath.split(' ')[1],
        Link: values.link_da.length ? values.link_da : values.link_en,
      };
      const reqObject: HostelEvent = {
        ItemId: '',
        ItemName: values.title_en,
        HostelName: hostelVariant,
        Event: {
          en: reqObjectEN,
          da: reqObjectDA,
          startDate: sitecoreStartIsoDate(startDate),
          endDate: endDate ? sitecoreEndIsoDate(endDate) : '',
          recurringDays: recurringDays,
        },
      };
      await axios
        .post(`/api/hostelevent/createevent`, reqObject)
        .then(() => {
          setEventCreated(true);
          setTimeout(() => {
            location.reload();
          }, 500);
        })
        .catch((err) => {
          throw err;
        });
    } catch (e) {
      console.log(e);
      setHasError(true);
    } finally {
      console.log('finally');
    }
  }

  useEffect(() => {
    axios
      //TODO: check Folder not found for folderPath: /sitecore/media library/Project/EventImages"
      .get<{ EventColors: Array<EventColor>; MediaImagesModel: Array<MediaImage> }>(`/api/hostelevent/getmedia`)
      .then((res) => {
        //filter out colors that are not for the current hostel variant
        if (hostelVariant === 'nex') {
          res.data.EventColors = res.data.EventColors.filter(
            (color) => allowedColorsNex.indexOf(color.ColorName) !== -1
          );
        } else {
          res.data.EventColors = res.data.EventColors.filter(
            (color) => allowedColorsSte.indexOf(color.ColorName) !== -1
          );
        }
        setEventColors(res.data.EventColors);
        setImagePaths(res.data.MediaImagesModel);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const styles = useMultiStyleConfig('HostelEventCalendar', {});

  return eventToEdit ? (
    <HostelEventEdit eventToEdit={eventToEdit} />
  ) : (
    <form id="contactForm" name="contactForm" onSubmit={handleSubmit(onSubmit)}>
      <Heading size="sm" marginBottom="1rem">
        Create event
      </Heading>
      <Flex gap={'8px'} direction={'row'}>
        <FormControl isRequired sx={styles.timeControl} width="60%">
          <FormLabel whiteSpace="nowrap">{'Start time'}</FormLabel>
          <Box sx={styles.timeBox} className="timeBox">
            <DatePicker
              selected={startTime}
              onChange={(date) => setStartTime(date!)}
              minTime={setHours(setMinutes(new Date(), 0), 0)}
              maxTime={setHours(setMinutes(new Date(), 45), 23)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption={''}
              dateFormat="HH:mm"
              locale={da}
            />
          </Box>
        </FormControl>
        <FormControl sx={styles.timeControl} width="60%">
          <FormLabel whiteSpace="nowrap">{'End time'}</FormLabel>
          <Box sx={styles.timeBox}>
            <DatePicker
              selected={endTime}
              onChange={(date) => setEndTime(date!)}
              minTime={setHours(setMinutes(new Date(), 0), 0)}
              maxTime={setHours(setMinutes(new Date(), 45), 23)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption={''}
              dateFormat="HH:mm"
              locale={da}
            />
            {endTime && (
              <CloseIcon
                backgroundColor={'lightgray'}
                color={'white'}
                borderRadius="full"
                padding="3px"
                onClick={() => setEndTime(null)}
              />
            )}
          </Box>
        </FormControl>
        <FormControl isRequired sx={styles.dateControl}>
          <FormLabel whiteSpace="nowrap">{'Date'}</FormLabel>
          <DatePicker
            withPortal={false}
            selected={startDate}
            onChange={onChangeStartDate}
            minDate={new Date()}
            maxDate={addMonths(new Date(), 12)}
            calendarClassName="datepickerEditEventCalendar"
            dateFormat={'dd/MM/yyyy'}
          />
        </FormControl>
      </Flex>
      <FormControl sx={styles.recurringCheckbox} width="25%">
        <Checkbox isChecked={recurringChecked} onChange={(e) => onChangeRecurring(e)} />
        <FormLabel>Recurring</FormLabel>
      </FormControl>
      {recurringChecked && (
        <>
          <FormControl sx={styles.daysGroup}>
            <CheckboxGroup defaultValue={[]} onChange={(e) => setRecurringDays(e)}>
              <Checkbox value="monday">Monday</Checkbox>
              <Checkbox value="tuesday">Tuesday</Checkbox>
              <Checkbox value="wednesday">Wednesday</Checkbox>
              <Checkbox value="thursday">Thursday</Checkbox>
              <Checkbox value="friday">Friday</Checkbox>
              <Checkbox value="saturday">Saturday</Checkbox>
              <Checkbox value="sunday">Sunday</Checkbox>
            </CheckboxGroup>
          </FormControl>
          <FormControl sx={{ ...styles.dateControl, marginY: '1rem' }} width="60%">
            <FormLabel>{'End date'}</FormLabel>
            <DatePicker
              withPortal={false}
              selected={endDate}
              onChange={(date) => setEndDate(date!)}
              minDate={addDays(startDate!, 1)}
              maxDate={addMonths(new Date(), 12)}
              calendarClassName="datepickerEditEventCalendar"
              dateFormat={'dd/MM/yyyy'}
            />
          </FormControl>
        </>
      )}
      <FormControl isInvalid={!!errors.imagePath} variant="floatingHostelCreator" isRequired>
        <Select
          defaultValue={1}
          {...register('imagePath', {
            required: 'Field required',
            onChange: (e) => setCurrentImagePath(e.target.value.split(' ')[0]),
          })}
        >
          <option key={''} value={''}>
            {' '}
          </option>
          {imagePaths &&
            imagePaths.map((image) => (
              <option key={image.ItemId} value={`${image.ItemUrl} ${image.ItemId}`}>
                {image.ItemName}
              </option>
            ))}
        </Select>
        <FormLabel className="selectLabel">{'Choose image'}</FormLabel>
        <FormErrorMessage>{errors.imagePath && errors.imagePath.message}</FormErrorMessage>
        {currentImagePath && (
          <Box sx={styles.imageBox}>
            <NextImage
              // field={{ value: { src: `${process.env.NEXT_PUBLIC_SITECORE_API_HOST}${currentImagePath}` } }}
              field={{ value: { src: `${process.env.NEXT_PUBLIC_SITECORE_API_HOST}${currentImagePath}` } }}
              style={{ objectFit: 'cover' }}
              fill={true}
              editable={true}
              unoptimized={true}
              imageParams={{ cw: 500, ch: 500, cx: 0.5, cy: 0.5 }}
            />
          </Box>
        )}
      </FormControl>
      <FormControl isInvalid={!!errors.eventColor} variant="floatingHostelCreator" isRequired>
        <Select
          defaultValue={''}
          {...register('eventColor', {
            required: 'Field required',
            onChange: (e) => setCurrentColor(e.target.value.split('|')[0]),
          })}
        >
          <option key={''} value={''}>
            {' '}
          </option>
          {eventColors?.map((color) => (
            <option key={color.ColorId} value={`${color.ColorCode}|${color.ColorName}`}>
              {color.ColorName}
            </option>
          ))}
        </Select>
        <FormLabel className="selectLabel">{'Event Color'}</FormLabel>
        <FormErrorMessage>{errors.eventColor && errors.eventColor.message}</FormErrorMessage>
        <Box
          sx={{ backgroundColor: currentColor ?? 'transparent', border: currentColor ? '1px solid #4E5356' : 'none' }}
          __css={styles.colorSpan}
        />
      </FormControl>
      <Flex gap={'5px'} direction={'row'}>
        <FormControl isInvalid={!!errors.title_en} variant="floatingHostelCreator" isRequired>
          <Input
            id="title_en"
            defaultValue={''}
            placeholder={'English title'}
            {...register('title_en', {
              required: 'Field required',
              maxLength: { value: 28, message: 'Max 28 chars allowed' },
              minLength: { value: 3, message: 'Title must be at least 3 chars long' },
              pattern: {
                //do not allow _ in title
                value: /^[^_]+$/,
                message: 'Title must not contain _',
              },
            })}
          />
          <FormLabel>{'English title'}</FormLabel>
          <FormErrorMessage>{errors.title_en && errors.title_en.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.title_da} variant="floatingHostelCreator">
          <Input
            id="title_da"
            defaultValue={''}
            placeholder={'Dansk titel'}
            {...register('title_da', {
              maxLength: { value: 28, message: 'Max 28 chars allowed' },
              minLength: { value: 3, message: 'Title must be at least 3 chars long' },
              pattern: {
                //do not allow _ in title
                value: /^[^_]+$/,
                message: 'Title must not contain _',
              },
            })}
          />
          <FormLabel>{'Dansk titel'}</FormLabel>
          <FormErrorMessage>{errors.title_da && errors.title_da.message}</FormErrorMessage>
        </FormControl>
      </Flex>
      <Flex gap={'5px'} direction={'row'}>
        <FormControl isInvalid={!!errors.description_en} variant="floatingHostelCreator" isRequired>
          <Textarea
            id="description_en"
            defaultValue={''}
            rows={3}
            resize="none"
            placeholder={'English description'}
            __css={styles.textarea}
            {...register('description_en', {
              required: 'Field required',
              maxLength: { value: 65, message: 'Max 65 chars allowed' },
              minLength: { value: 3, message: 'Description must be at least 3 chars long' },
            })}
          />
          <FormLabel>{'English description'}</FormLabel>
          <FormErrorMessage>{errors.description_en && errors.description_en.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.description_da} variant="floatingHostelCreator">
          <Textarea
            id="description_da"
            defaultValue={''}
            rows={3}
            resize="none"
            placeholder={'Dansk beskrivelse'}
            {...register('description_da', {
              maxLength: { value: 65, message: 'Max 65 chars allowed' },
              minLength: { value: 3, message: 'Description must be at least 3 chars long' },
            })}
          />
          <FormLabel>{'Dansk beskrivelse'}</FormLabel>
          <FormErrorMessage>{errors.description_da && errors.description_da.message}</FormErrorMessage>
        </FormControl>
      </Flex>
      <Flex gap={'5px'} direction={'row'}>
        <FormControl isInvalid={!!errors.location_en} variant="floatingHostelCreator" isRequired>
          <Input
            id="location_en"
            defaultValue={''}
            placeholder={'English location'}
            {...register('location_en', {
              required: 'Field required',
              maxLength: { value: 20, message: 'Max 20 chars allowed' },
              minLength: { value: 3, message: 'Location must be at least 3 chars long' },
            })}
          />
          <FormLabel>{'English location'}</FormLabel>
          <FormErrorMessage>{errors.location_en && errors.location_en.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.location_da} variant="floatingHostelCreator">
          <Input
            id="location_da"
            defaultValue={''}
            placeholder={'Dansk lokation'}
            {...register('location_da', {
              maxLength: { value: 20, message: 'Max 20 chars allowed' },
              minLength: { value: 3, message: 'Location must be at least 3 chars long' },
            })}
          />
          <FormLabel>{'Dansk lokation'}</FormLabel>
          <FormErrorMessage>{errors.location_da && errors.location_da.message}</FormErrorMessage>
        </FormControl>
      </Flex>
      <Flex gap={'5px'} direction={'row'} marginBottom="2rem">
        <FormControl isInvalid={!!errors.link_en} variant="floatingHostelCreator">
          <Input
            id="link_en"
            defaultValue={''}
            placeholder={'English link'}
            {...register('link_en', {
              pattern: {
                value:
                  /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/,
                message: 'Enter a valid URL',
              },
            })}
          />
          <FormLabel>{'English link'}</FormLabel>
          <FormErrorMessage>{errors.link_en && errors.link_en.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.link_da} variant="floatingHostelCreator">
          <Input
            id="link_da"
            defaultValue={''}
            placeholder={'Dansk link'}
            {...register('link_da', {
              pattern: {
                value:
                  /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/,
                message: 'Enter a valid URL',
              },
            })}
          />
          <FormLabel>{'Dansk link'}</FormLabel>
          <FormErrorMessage>{errors.link_da && errors.link_da.message}</FormErrorMessage>
        </FormControl>
      </Flex>
      {isSubmitting && <Spinner ml={4} size="sm" />}
      <CButton
        text="Submit"
        size="large"
        onClick={handleSubmit((data) => onSubmit(data))}
        textAlign="center"
        color="black"
        backgroundColor={hostelVariant === 'nex' ? '#00ffc4' : '#eaff00'}
        type="submit"
        editEventCalendar={true}
      />
      {eventCreated && <h3 style={{ color: 'green' }}>Event created successfully!!</h3>}
      {hasError && <h3 style={{ color: 'red' }}>Something went wrong, please try again later</h3>}
    </form>
  );
};
export default HostelEventCreator;
