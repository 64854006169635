import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const LinkArrowLeftIcon = (props: IconProps): ReactElement => {
  return (
    <Icon {...props} viewBox="0 0 30 30">
      <g fill="transparent">
        <circle cx="15" cy="15" r="14" />
        <line x1="7" y1="15" x2="22" y2="15" stroke="currentColor" />
        <path d="M12 10C12 12.7614 9.76142 15 7 15" stroke="currentColor" />
        <path d="M7 15C9.76142 15 12 17.2386 12 20" stroke="currentColor" />
      </g>
    </Icon>
  );
};
