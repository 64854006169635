import { Box, Flex, useMultiStyleConfig, Text, Heading } from '@chakra-ui/react';
import { LinkArrowLeftIcon, LinkArrowRightIcon } from 'assets/Icons/ui';
import axios from 'axios';
import { addDays, format, subDays, isBefore, isAfter, isSameDay } from 'date-fns';
import { shortISODateFormat } from 'lib/booking-box-helpers';
import { eventCalendarItemTextColor, EventColor, MediaImage, weekObj } from 'lib/helpers';
import React, { ReactElement, useEffect, useState } from 'react';
import { useHostelCreatorStore } from 'src/store';
import { getPreviousMonday, HostelEvent, hostelEventsWeek } from './helpers';

interface IProps {
  loadEvent: (itemId: string | null) => void;
}

const HostelEventCalendar: React.FC<IProps> = ({ loadEvent }): ReactElement => {
  const { hostelVariant } = useHostelCreatorStore((state) => state);

  //use getDay() + 6 to make monday start at index 0 so we can use weekObj to get day name
  const currentDay = weekObj[(new Date().getDay() + 6) % 7];
  const [eventsArray, setEventsArray] = useState<hostelEventsWeek[]>();
  const [eventColors, setEventColors] = useState<EventColor[]>([]);
  const [requestDay, setRequestDay] = useState<Date>(new Date().getDay() === 1 ? new Date() : getPreviousMonday());
  const [highlightedEvent, setHighlightedEvent] = useState<string>('');
  const lastDay = addDays(requestDay, 27);

  const loadEditEvent = (itemId: string) => {
    if (!highlightedEvent || highlightedEvent !== itemId) {
      setHighlightedEvent(itemId);
      loadEvent(itemId);
      return;
    }
    setHighlightedEvent('');
    loadEvent(null);
  };

  const isOneWeekFromToday = (requestDay: Date, indexJ: number, index: number) => {
    return (
      isSameDay(addDays(requestDay, indexJ + index * 7), new Date()) ||
      (isBefore(addDays(requestDay, indexJ + index * 7), addDays(new Date(), 6)) &&
        isAfter(addDays(requestDay, indexJ + index * 7), new Date()))
    );
  };

  useEffect(() => {
    axios
      //TODO: check Folder not found for folderPath: /sitecore/media library/Project/EventImages"
      .get<{ EventColors: Array<EventColor>; MediaImagesModel: Array<MediaImage> }>(`/api/hostelevent/getmedia`)
      .then((res) => {
        setEventColors(res.data.EventColors);
      });
  }, []);

  useEffect(() => {
    const reqObject = {
      HostelName: hostelVariant,
      startDate: shortISODateFormat(requestDay),
    };
    axios.post<hostelEventsWeek[]>(`/api/hostelevent/getallevents`, reqObject).then((res) => {
      setEventsArray(res.data);
    });
  }, [requestDay, hostelVariant]);

  const styles = useMultiStyleConfig('HostelEventCalendar', {});

  return eventsArray ? (
    <>
      <Heading size="sm" marginBottom="1rem">
        <Flex justify="space-between">
          {`${format(requestDay, 'dd/MMMM')} - ${format(lastDay, 'dd/MMMM')}`}
          <Flex align="center" gap="1rem">
            <LinkArrowLeftIcon stroke="black" cursor="pointer" onClick={() => setRequestDay(subDays(requestDay, 28))} />
            <LinkArrowRightIcon
              stroke="black"
              cursor="pointer"
              onClick={() => setRequestDay(addDays(requestDay, 28))}
            />
          </Flex>
        </Flex>
      </Heading>
      <Flex sx={styles.dayContainerHeader}>
        {Object.entries(weekObj).map((day) => {
          return (
            <Box key={day[1]} sx={styles.dayContainer}>
              <Text
                sx={styles.dayHeader}
                style={{
                  fontWeight: currentDay === day[1] ? 'bold' : 'normal',
                }}
              >
                {day[1]}
              </Text>
            </Box>
          );
        })}

        {/* Loop when eventsArray is one array of weekDays */}
        {/* <Flex sx={styles.dayContainerEvents}>
              {eventsArray[day[1] as keyof hostelEventsWeek].map((event, index) => (
                <Box
                  key={`${event.title} ${index}`}
                  sx={styles.calendarEvent}
                  background={event.cardColor.fields.colorcode.value}
                >
                  {event.startTime.value + ' - ' + event.title.value}
                </Box>
              ))}
            </Flex> */}
      </Flex>
      {eventsArray.map((week, index) => {
        return (
          <Flex sx={styles.eventDayContainer} key={`${week} ${index}`} className="eventDayContainer">
            {Object.entries(week).map((day, indexJ) => {
              return (
                <Box key={indexJ} sx={styles.eventContainer} className="eventContainer">
                  {/* take firstMonday (requestDay) as first eventContainer then add 7 days for each row of eventDayContainer */}
                  <Text
                    sx={styles.eventContainerDate}
                    className={isOneWeekFromToday(requestDay, indexJ, index) ? 'eventContainerDateHighlighted' : ''}
                  >
                    {format(addDays(requestDay, indexJ + index * 7), 'dd/MM')}
                  </Text>
                  {day[1]
                    .sort((a, b) => a.Event.en.StartTime.localeCompare(b.Event.en.StartTime))
                    .map((event, indexJ) => (
                      <Box
                        key={`${event.Event.en.Title} ${indexJ}`}
                        sx={styles.calendarEvent}
                        //replace with comment code, after MEC test
                        //background={event.Event.en.CardColorCode}
                        background={
                          eventColors.find((color) => color.ColorName === event.Event.en.CardColor)?.ColorCode
                        }
                        onClick={() => loadEditEvent(event.ItemId)}
                        border={
                          highlightedEvent === event.ItemId
                            ? '2px solid #4283D1'
                            : event.Event.en.CardColor === 'Off-white'
                            ? '1px solid #4e5256'
                            : ''
                        }
                        boxShadow={highlightedEvent === event.ItemId ? '0px 1px 5px 0px rgba(36, 71, 255, 0.30)' : ''}
                        padding={
                          highlightedEvent === event.ItemId
                            ? '3px'
                            : event.Event.en.CardColor === 'Off-white'
                            ? '4px'
                            : '5px'
                        }
                        color={getEventTextColor(event)}
                      >
                        {event.Event.en.StartTime + ' - ' + event.Event.en.Title}
                      </Box>
                    ))}
                </Box>
              );
            })}
          </Flex>
        );
      })}
    </>
  ) : (
    <> </>
  );
};
export default HostelEventCalendar;

function getEventTextColor(event: HostelEvent) {
  return eventCalendarItemTextColor[event.Event.en.CardColor]
    ? eventCalendarItemTextColor[event.Event.en.CardColor]
    : 'White';
}
