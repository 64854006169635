import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import * as bookingTypes from './booking-store-modules';

export const useDormStore = create<bookingTypes.DormState>()(
  devtools(
    (set) => ({
      setDormsData: (DormsData) => set({ DormsData: DormsData }),
      setDormRooms: (DormRooms) => set({ DormRooms: DormRooms }),
      soldoutDorms: [],
      setSoldoutDorms: (soldoutDorms) => set({ soldoutDorms: soldoutDorms }),
    }),
    { name: 'dorm-storage' }
  )
);

const updateCartItemGuests = (
  cartItems: Array<bookingTypes.CartItem>,
  id: string,
  guestsIncrementor: number
): Array<bookingTypes.CartItem> =>
  cartItems.map((cartItem) => ({
    ...cartItem,
    numberOfGuests: cartItem.RoomType === id ? cartItem.numberOfGuests + guestsIncrementor : cartItem.numberOfGuests,
    packages:
      cartItem.RoomType === id
        ? cartItem.packages.map((pck) => ({
            ...pck,
            Count: pck.PackagePriceChildCategory !== 'CHI' ? pck.Count! + guestsIncrementor : pck.Count,
          }))
        : cartItem.packages,
  }));

const addCartItem = (
  cartItems: Array<bookingTypes.CartItem>,
  cartItem: bookingTypes.CartItem
): Array<bookingTypes.CartItem> => {
  const newCartItems = [...cartItems, cartItem];
  return newCartItems;
};

//wip, move next to extraPackage1
const addExtraPackage1Details = (
  package1DetailsList: Array<bookingTypes.PackageBox>,
  package1Details: bookingTypes.PackageBox
): Array<bookingTypes.PackageBox> => {
  //check if package is already in the list
  const packageAlreadyInList = package1DetailsList.some(
    (packageInList) => packageInList.PackageCode === package1Details.PackageCode
  );
  if (packageAlreadyInList) {
    return package1DetailsList;
  }
  const newExtraPackage1List = [...package1DetailsList, package1Details];
  return newExtraPackage1List;
};

const setExtraPackage1DetailsActive = (
  package1DetailsList: Array<bookingTypes.PackageBox>,
  packageCode: string,
  newState: boolean
) => {
  return package1DetailsList.map((package1Details) => ({
    ...package1Details,
    Active: package1Details.PackageCode === packageCode ? newState : package1Details.Active,
  }));
};

const setAllExtraPackage1DetailsNotActive = (package1DetailsList: Array<bookingTypes.PackageBox>) => {
  return package1DetailsList.map((package1Details) => ({
    ...package1Details,
    Active: false,
  }));
};

const removeCartItem = (cartItems: Array<bookingTypes.CartItem>, id: number): Array<bookingTypes.CartItem> => {
  return cartItems.filter((cartItem) => cartItem.identifier !== id);
};

const setExtraPackageActive = (
  cartItems: Array<bookingTypes.CartItem>,
  extraPackageDetails: bookingTypes.PackageBox
): Array<bookingTypes.CartItem> => {
  return cartItems.map((cartItem) => ({
    ...cartItem,
    packages: [
      ...cartItem.packages,
      {
        ...extraPackageDetails,
        Count: cartItem.numberOfGuests,
      },
    ],
  }));
};

const clearExtraPackage = (
  cartItems: Array<bookingTypes.CartItem>,
  packageCode: string
): Array<bookingTypes.CartItem> => {
  return cartItems.map((cartItem) => ({
    ...cartItem,
    packages: cartItem.packages.filter(
      (thisPackage: bookingTypes.PackageBox) => thisPackage.PackageCode !== packageCode
    ),
  }));
};

//when numChildren = 0
//make clearExtraPackageChildren where we filter out packageCode = BUCH
const clearExtraPackageChildrenSelect = (
  cartItems: Array<bookingTypes.CartItem>,
  id: number,
  packageCode: string
): Array<bookingTypes.CartItem> => {
  return cartItems.map((cartItem) => ({
    ...cartItem,
    packages:
      cartItem.identifier === id
        ? cartItem.packages.filter((pck: bookingTypes.PackageBox) => pck.PackageCode !== packageCode)
        : cartItem.packages,
  }));
};

const setExtraPackageChildrenSelectActive = (
  cartItems: Array<bookingTypes.CartItem>,
  id: number,
  numChildren: number,
  extraPackageDetails: bookingTypes.PackageBox
): Array<bookingTypes.CartItem> => {
  return cartItems.map((cartItem) => ({
    ...cartItem,
    packages:
      cartItem.identifier === id
        ? [
            ...cartItem.packages,
            {
              ...extraPackageDetails,
              Count: numChildren,
            },
          ]
        : cartItem.packages,
  }));
};

const updateCartItemPackage2 = (
  cartItems: Array<bookingTypes.CartItem>,
  id: number,
  package1Code: string,
  numAdults: number,
  package2Code: string,
  numChildren: number
): Array<bookingTypes.CartItem> =>
  cartItems.map((cartItem) => ({
    ...cartItem,
    packages:
      cartItem.identifier === id
        ? cartItem.packages.map((pck) => {
            return {
              ...pck,
              Count:
                pck.PackageCode === package1Code
                  ? numAdults
                  : pck.PackageCode === package2Code
                  ? numChildren
                  : pck.Count,
            };
          })
        : cartItem.packages,
  }));

export const useCartStore = create<bookingTypes.CartStore>()(
  devtools(
    persist(
      (set) => ({
        identifier: 0,
        cartItemsIds: [],
        setCartItemIds: (cartItems) => set({ cartItemsIds: cartItems }),
        cartItems: [],
        setCartItems: (cartItems) => set({ cartItems: cartItems }),
        addCartItemId: (id: string) =>
          set((state) => ({
            ...state,
            cartItemsIds: state.cartItemsIds.includes(id) ? [...state.cartItemsIds] : [...state.cartItemsIds, id],
          })),
        addCartItem: (cartItem) =>
          set((state) => ({
            ...state,
            cartItems: addCartItem(state.cartItems, cartItem),
            identifier: state.identifier + 1,
          })),
        removeCartId: (id) =>
          set((state) => ({
            ...state,
            cartItemsIds: state.cartItemsIds.filter(function (item) {
              return item !== id;
            }),
          })),
        updateCartItemGuests: (id: string, guestsIncrementor: number) =>
          set((state) => ({
            ...state,
            cartItems: updateCartItemGuests(state.cartItems, id, guestsIncrementor),
          })),
        removeCartItem: (id: number) =>
          set((state) => ({
            ...state,
            cartItems: removeCartItem(state.cartItems, id),
          })),
        cartFetchingDates: { checkIn: null, checkOut: null },
        setCartFetchingDates: (fetchingDates) => set({ cartFetchingDates: fetchingDates }),
        flexCart: false,
        setFlexCart: (value) => set({ flexCart: value }),
        //extra Packages
        setExtraPackageActive: (extraPackageDetails) =>
          set((state) => ({
            ...state,
            cartItems: setExtraPackageActive(state.cartItems, extraPackageDetails),
          })),
        extraPackage2Details: bookingTypes.defaultExtraPackage2Details,
        setExtraPackage2Details: (value) => set({ extraPackage2Details: value }),
        package1DetailsList: [],
        addExtraPackage1Details: (package1Details) =>
          set((state) => ({
            ...state,
            package1DetailsList: addExtraPackage1Details(state.package1DetailsList, package1Details),
          })),
        setExtraPackage1DetailsActive: (packageCode, newState) =>
          set((state) => ({
            ...state,
            package1DetailsList: setExtraPackage1DetailsActive(state.package1DetailsList, packageCode, newState),
          })),
        setAllExtraPackage1DetailsNotActive: () =>
          set((state) => ({
            ...state,
            package1DetailsList: setAllExtraPackage1DetailsNotActive(state.package1DetailsList),
          })),
        extraPackage2Checked: false,
        setExtraPackage2Checked: (value) => {
          set({ extraPackage2Checked: value });
        },
        clearExtraPackage: (packageCode) =>
          set((state) => ({
            ...state,
            cartItems: clearExtraPackage(state.cartItems, packageCode),
          })),
        updateCartItemPackage2: (identifier, package1Code, numAdults, package2Code, numChildren) =>
          set((state) => ({
            ...state,
            cartItems: updateCartItemPackage2(
              state.cartItems,
              identifier,
              package1Code,
              numAdults,
              package2Code,
              numChildren
            ),
          })),
        clearExtraPackageChildrenSelect: (identifier, packageCode) =>
          set((state) => ({
            ...state,
            cartItems: clearExtraPackageChildrenSelect(state.cartItems, identifier, packageCode),
          })),
        setExtraPackageChildrenSelectActive: (identifier, numChildren, extraPackageDetails) =>
          set((state) => ({
            ...state,
            cartItems: setExtraPackageChildrenSelectActive(
              state.cartItems,
              identifier,
              numChildren,
              extraPackageDetails
            ),
          })),
        //true when cart has company rates inside
        companyRatesActive: false,
        setCompanyRatesActive: (value) => set({ companyRatesActive: value }),
      }),
      {
        name: 'cart-storage',
        // onRehydrateStorage: (state) => {
        //   console.log('rehydrate', document.cookie);
        // },
      }
      // {
      //   name: 'cart-storage',
      //   getStorage: () => sessionStorage,
      // }
    ),
    { name: 'cart-storage' }
  )
);

export const useBookingMenuDatesStore = create<bookingTypes.MenuDatesStore>()(
  devtools(
    persist(
      (set) => ({
        menuFetchingDates: { checkIn: null, checkOut: null },
        setMenuFetchingDates: (menuFetchingDates) => set({ menuFetchingDates: menuFetchingDates }),
        oldFetchingDates: { checkIn: null, checkOut: null },
        setOldFetchingDates: (oldFetchingDates) => set({ oldFetchingDates: oldFetchingDates }),
        flex: false,
        setFlex: (value) => set({ flex: value }),
        isFlexDisabled: false,
        setIsFlexDisabled: (value) => set({ isFlexDisabled: value }),
        isRestrictedDisabled: false,
        setIsRestrictedDisabled: (value) => set({ isRestrictedDisabled: value }),
        // ONG-3887 function to handle google links with quick search
        trackingDates: { checkIn: null, checkOut: null },
        setTrackingDates: (trackingDates) => set({ trackingDates: trackingDates }),
      }),
      { name: 'menu-dates-storage' }
    ),
    { name: 'menu-dates-storage' }
  )
);

export const useBookingMenuStore = create<bookingTypes.BookingMenuStore>()(
  //NEVER USE PERSIST ON THIS
  devtools(
    (set) => ({
      modalOpen: false,
      setModalOpen: (value) => set({ modalOpen: value }),
      datePickerOpen: false,
      setDatePickerOpen: (value) => set({ datePickerOpen: value }),
      roomPickerOpen: false,
      setRoomPickerOpen: (value) => set({ roomPickerOpen: value }),
      errorStepOpen: false,
      setErrorStepOpen: (value) => set({ errorStepOpen: value }),
      paymentStepOpen: false,
      setPaymentStepOpen: (value) => set({ paymentStepOpen: value }),
      changeStepOpen: false,
      setChangeStepOpen: (value) => set({ changeStepOpen: value }),
      loading: false,
      setLoading: (value) => set({ loading: value }),
    }),
    { name: 'booking-menu-storage' }
  )
);

export const useCurrencyStore = create<bookingTypes.CurrencyStore>()(
  devtools(
    persist(
      (set) => ({
        currencyObject: bookingTypes.staticExchangeRates,
        setCurrencyObject: (value) => set({ currencyObject: value }),
        currentCurrency: bookingTypes.staticExchangeRates['DKK'],
        setCurrentCurrency: (value) =>
          set((state) => ({
            ...state,
            currentCurrency: state.currencyObject[value],
          })),
      }),
      { name: 'currency-storage' }
    ),
    { name: 'currency-storage' }
  )
);

export const useCompanyStore = create<bookingTypes.CompanyStore>()(
  devtools(
    persist(
      (set) => ({
        //means roomPicker shows company prices
        companyActive: false,
        setCompanyActive: (value) => set({ companyActive: value }),
        loggedIn: false,
        setLoggedIn: (value) => set({ loggedIn: value }),
        companyDetails: bookingTypes.companyDetailsConst,
        setCompanyDetails: (value) => set({ companyDetails: value }),
        firstAvailableRateCode: '',
        setFirstAvailableRateCode: (value) => set({ firstAvailableRateCode: value }),
      }),
      { name: 'company-storage' }
    ),
    { name: 'company-storage' }
  )
);

export const useContactFormStore = create<bookingTypes.ContactFormStore>()(
  devtools(
    (set) => ({
      agreeTerms: false,
      setAgreeTerms: (value) => set({ agreeTerms: value }),
      agreeError: false,
      setAgreeError: (value) => set({ agreeError: value }),
      paymentLoading: false,
      setPaymentLoading: (value) => set({ paymentLoading: value }),
      newsletterChecked: false,
      setNewsletterChecked: (value) => set({ newsletterChecked: value }),
    }),
    { name: 'contact-storage' }
  )
);

export const useSitecoreFieldsStore = create<bookingTypes.SitecoreFieldsStore>()(
  devtools(
    (set) => ({
      sitecoreFields: bookingTypes.sitecoreFields,
      setSitecoreFields: (value) => set({ sitecoreFields: value }),
    }),
    { name: 'sitecore-storage' }
  )
);

export const useChangeBookingStore = create<bookingTypes.ChangeBookingStore>()(
  devtools(
    (set) => ({
      changeReservationDetails: null,
      setChangeReservationDetails: (value) => set({ changeReservationDetails: value }),
      datePickerChangeActive: false,
      setDatePickerChangeActive: (value) => set({ datePickerChangeActive: value }),
      changeReservationDates: { checkIn: null, checkOut: null },
      setChangeReservationDates: (changeReservationDates) => set({ changeReservationDates: changeReservationDates }),
    }),
    { name: 'change-booking-storage' }
  )
);

export const useMenuStore = create<{
  menuIsOpen: boolean;
  langAndCurrPickerIsOpen: boolean;
  setMenuIsOpen: (value: boolean, langCurrOpen?: boolean) => void;
}>()(
  devtools(
    (set) => ({
      menuIsOpen: false,
      langAndCurrPickerIsOpen: false,
      setMenuIsOpen: (value: boolean, langCurrOpen?: boolean) =>
        set({ menuIsOpen: value, langAndCurrPickerIsOpen: langCurrOpen }),
    }),
    { name: 'menu-storage' }
  )
);

export const useHostelCreatorStore = create<bookingTypes.HostelCreatorStore>()(
  devtools(
    persist(
      (set) => ({
        hostelVariant: '',
        setHostelVariant: (value) => set({ hostelVariant: value }),
      }),
      { name: 'hostel-creator-storage' }
    ),
    { name: 'hostel-creator-storage' }
  )
);

export const useGeneralStore = create<bookingTypes.GeneralStore>()(
  devtools(
    (set) => ({
      loaded: false,
      setLoaded: (value) => set({ loaded: value }),
      isDesktop: false,
      setIsDesktop: (value) => set({ isDesktop: value }),
      maintenanceActive: false,
      setMaintenanceActive: (value) => set({ maintenanceActive: value }),
    }),
    { name: 'general-storage' }
  )
);

type CustomerStoreType = {
  email: string;
  phone: string;
  country: string;
  setCustomerData: (email: string, phone: string, country: string) => void;
};

export const useCustomerStore = create<CustomerStoreType>()(
  devtools(
    persist(
      (set) => ({
        email: '',
        phone: '',
        country: '',
        setCustomerData: (email: string, phone: string, country: string) => set({ email, phone, country }),
      }),
      { name: 'customer-store' }
    ),
    { name: 'customer-store' }
  )
);

interface LanguageState {
  currentLanguage: string;
  setLanguage: (language: string) => void;
}

export const useLanguageStore = create<LanguageState>((set) => ({
  currentLanguage: 'en', // default language
  setLanguage: (language) => set({ currentLanguage: language }),
}));
