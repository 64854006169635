import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const CloseIcon = (props: IconProps): ReactElement => {
  return (
    <Icon {...props} viewBox="0 0 25 25">
      <path d="M19.7877 4.98779L20.6612 5.86128L5.81196 20.7105L4.93848 19.837L19.7877 4.98779Z" fill="currentColor" />
      <path d="M20.6624 19.837L19.7889 20.7105L4.93963 5.86128L5.81311 4.98779L20.6624 19.837Z" fill="currentColor" />
    </Icon>
  );
};
