import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Spinner,
  useMultiStyleConfig,
  Text,
  Textarea,
  Heading,
} from '@chakra-ui/react';
import React, { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { addDays, addMonths, format, isBefore, setHours, setMinutes } from 'date-fns';
import axios from 'axios';
import { EventColor, HostelCreatorForm, MediaImage } from 'lib/helpers';
import { useHostelCreatorStore } from 'src/store';
import {
  allowedColorsNex,
  allowedColorsSte,
  HostelEvent,
  HostelEventCulture,
  sitecoreEndIsoDate,
  sitecoreStartIsoDate,
} from './helpers';
import { NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import da from 'date-fns/locale/da';
import { CloseIcon } from 'assets/Icons/ui';
import CButton from 'components/buttons/CButton';

interface IProps {
  eventToEdit: HostelEvent;
}

const HostelEventEdit: React.FC<IProps> = ({ eventToEdit }): ReactElement => {
  //make a date from the eventToEdit.startDate and add the hours and minutes from the eventToEdit.en.StartTime
  const [startTime, setStartTime] = useState<Date | number>(
    new Date(eventToEdit.Event.startDate).setHours(
      parseInt(eventToEdit.Event.en.StartTime.split(':')[0]),
      parseInt(eventToEdit.Event.en.StartTime.split(':')[1])
    )
  );
  const [endTime, setEndTime] = useState<Date | number | null>(null);
  //use substring to remove the timestamp
  const [startDate, setStartDate] = useState<Date>(new Date(eventToEdit.Event.startDate.substring(0, 10)));
  const [endDate, setEndDate] = useState<Date | null>(
    eventToEdit.Event.endDate ? new Date(eventToEdit.Event.endDate.substring(0, 10)) : null
  );
  const [recurringChecked, setRecurringChecked] = useState(eventToEdit.Event.recurringDays.length > 0);
  const [eventColors, setEventColors] = useState<EventColor[]>([]);
  const [currentColor, setCurrentColor] = useState<string | null>();
  const [imagePaths, setImagePaths] = useState<MediaImage[]>([]);
  const [currentImagePath, setCurrentImagePath] = useState<string | null>();
  const previousImage = '';

  const { hostelVariant } = useHostelCreatorStore((state) => state);
  const [eventUpdated, setEventUpdated] = useState<boolean>(false);
  const [eventDeleted, setEventDeleted] = useState<boolean>(false);
  const [eventCopied, setEventCopied] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [recurringDays, setRecurringDays] = useState<(string | number)[]>(eventToEdit.Event.recurringDays);

  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const onChangeStartDate = (date: Date | null) => {
    if (date === null) return;
    setStartDate(date);
    if (recurringChecked && endDate && isBefore(endDate, date)) {
      setEndDate(addDays(date, 1));
    }
  };

  const onChangeRecurring = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRecurringChecked(e.target.checked);
    e.target.checked ? setEndDate(addDays(startDate, 1)) : setEndDate(null);
  };

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<HostelCreatorForm>({
    mode: 'all',
    defaultValues: {
      title_en: eventToEdit.Event.en.Title,
      title_da: eventToEdit.Event.da.Title,
      description_en: eventToEdit.Event.en.Description,
      description_da: eventToEdit.Event.da.Description,
      imagePath: previousImage,
      eventColor: eventToEdit.Event.en.CardColor,
      location_da: eventToEdit.Event.da.Location,
      location_en: eventToEdit.Event.en.Location,
      link_en: eventToEdit.Event.en.Link,
      link_da: eventToEdit.Event.da.Link,
      recurringDays: [],
    },
  });

  async function onSubmit(values: HostelCreatorForm, isCopying: boolean) {
    if (recurringChecked && recurringDays.length === 0) {
      alert('Please select at least one day for recurring events.');
      return;
    }
    if (values.title_en.includes('_') || values.title_da.includes('_')) {
      alert('Title must not contain _');
      return;
    }
    //just to be sure
    if (recurringChecked && endDate === null) return;

    try {
      setHasError(false);
      const reqObjectEN: HostelEventCulture = {
        Title: values.title_en,
        Description: values.description_en,
        Location: values.location_en,
        StartTime: format(new Date(startTime), 'HH:mm'),
        EndTime: endTime ? format(new Date(endTime), 'HH:mm') : '',
        CardColor: values.eventColor.split('|')[1],
        CardColorCode: values.eventColor.split('|')[0],
        Image: values.imagePath.split(' ')[0],
        ImageId: values.imagePath.split(' ')[1],
        Link: values.link_en,
      };
      const reqObjectDA: HostelEventCulture = {
        Title: values.title_da.length ? values.title_da : values.title_en,
        Description: values.description_da.length ? values.description_da : values.description_en,
        Location: values.location_da.length ? values.location_da : values.location_en,
        StartTime: format(new Date(startTime), 'HH:mm'),
        EndTime: endTime ? format(new Date(endTime), 'HH:mm') : '',
        CardColor: values.eventColor.split('|')[1],
        CardColorCode: values.eventColor.split('|')[0],
        Image: values.imagePath.split(' ')[0],
        ImageId: values.imagePath.split(' ')[1],
        Link: values.link_da.length ? values.link_da : values.link_en,
      };
      const reqObject: HostelEvent = {
        ItemId: eventToEdit.ItemId,
        ItemName: eventToEdit.ItemName,
        HostelName: hostelVariant,
        Event: {
          en: reqObjectEN,
          da: reqObjectDA,
          startDate: sitecoreStartIsoDate(startDate),
          endDate: endDate && recurringChecked ? sitecoreEndIsoDate(endDate) : '',
          recurringDays: recurringDays,
        },
      };
      const requestURI = isCopying ? 'api/hostelevent/createevent' : 'api/hostelevent/updateevent';
      await axios
        .post(`${process.env.NEXT_PUBLIC_SITECORE_API_HOST}/${requestURI}`, reqObject)
        .then((res) => {
          console.log(res);
          isCopying ? setEventCopied(true) : setEventUpdated(true);
          setTimeout(() => {
            location.reload();
          }, 500);
        })
        .catch((err) => {
          console.error(err);
          setHasError(true);
        });
    } catch (e) {
      console.log(e);
      setHasError(true);
    } finally {
      console.log('finally');
    }
  }

  const deleteEvent = async () => {
    setIsDeleting(true);
    const reqObject = {
      HostelName: hostelVariant,
      ItemId: eventToEdit.ItemId,
    };
    try {
      setHasError(false);
      await axios
        .post(`${process.env.NEXT_PUBLIC_SITECORE_API_HOST}/api/hostelevent/deleteevent`, reqObject)
        .then((res) => {
          console.log(res);
          setEventDeleted(true);
          setTimeout(() => {
            location.reload();
          }, 500);
        })
        .catch((err) => {
          console.error(err);
          setHasError(true);
        });
    } catch (e) {
      console.log(e);
      setHasError(true);
    } finally {
      setIsDeleting(false);
      console.log('finally');
    }
  };

  useEffect(() => {
    axios
      //TODO: check Folder not found for folderPath: /sitecore/media library/Project/EventImages"
      .get<{ EventColors: Array<EventColor>; MediaImagesModel: Array<MediaImage> }>(
        `${process.env.NEXT_PUBLIC_SITECORE_API_HOST}/api/hostelevent/getmedia`
      )
      .then((res) => {
        if (hostelVariant === 'nex') {
          res.data.EventColors = res.data.EventColors.filter(
            (color) => allowedColorsNex.indexOf(color.ColorName) !== -1
          );
        } else {
          res.data.EventColors = res.data.EventColors.filter(
            (color) => allowedColorsSte.indexOf(color.ColorName) !== -1
          );
        }
        setEventColors(res.data.EventColors);
        setImagePaths(res.data.MediaImagesModel);
        const eventColor = res.data.EventColors.find((color) => color.ColorName === eventToEdit.Event.en.CardColor);
        //reset fields when clicking on different event in calendar
        reset({
          title_en: eventToEdit.Event.en.Title,
          title_da: eventToEdit.Event.da.Title,
          description_en: eventToEdit.Event.en.Description,
          description_da: eventToEdit.Event.da.Description,
          imagePath: `${eventToEdit.Event.en.Image} ${eventToEdit.Event.en.ImageId}`,
          eventColor: `${eventColor?.ColorCode}|${eventToEdit.Event.en.CardColor}`,
          location_en: eventToEdit.Event.en.Location,
          location_da: eventToEdit.Event.da.Location,
          link_en: eventToEdit.Event.en.Link,
          link_da: eventToEdit.Event.da.Link,
        });
        setStartTime(
          new Date(eventToEdit.Event.startDate).setHours(
            parseInt(eventToEdit.Event.en.StartTime.split(':')[0]),
            parseInt(eventToEdit.Event.en.StartTime.split(':')[1])
          )
        );
        setEndTime(
          new Date(eventToEdit.Event.endDate).setHours(
            parseInt(eventToEdit.Event.en.EndTime.split(':')[0]),
            parseInt(eventToEdit.Event.en.EndTime.split(':')[1])
          )
        );
        setStartDate(new Date(eventToEdit.Event.startDate));
        setEndDate(new Date(eventToEdit.Event.endDate));
        setRecurringChecked(eventToEdit.Event.recurringDays.length > 0);
        setRecurringDays(eventToEdit.Event.recurringDays);
        eventColor && setCurrentColor(eventColor.ColorCode);
        setCurrentImagePath(eventToEdit.Event.en.Image);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [eventToEdit]);

  const styles = useMultiStyleConfig('HostelEventCalendar', {});

  return (
    <form id="contactForm" name="contactForm">
      <Heading size="sm" marginBottom="1rem">
        Edit event
      </Heading>
      <Flex gap={'8px'} direction={'row'}>
        <FormControl isRequired sx={styles.timeControl} width="60%">
          <FormLabel whiteSpace="nowrap">{'Start time'}</FormLabel>
          <Box sx={styles.timeBox}>
            <DatePicker
              selected={new Date(startTime)}
              onChange={(date) => setStartTime(date!)}
              minTime={setHours(setMinutes(new Date(), 0), 0)}
              maxTime={setHours(setMinutes(new Date(), 45), 23)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption={''}
              dateFormat="HH:mm"
              locale={da}
            />
          </Box>
        </FormControl>
        <FormControl sx={styles.timeControl} width="60%">
          <FormLabel whiteSpace="nowrap">{'End time'}</FormLabel>
          <Box sx={styles.timeBox}>
            <DatePicker
              selected={endTime ? new Date(endTime) : null}
              onChange={(date) => setEndTime(date!)}
              minTime={setHours(setMinutes(new Date(), 0), 0)}
              maxTime={setHours(setMinutes(new Date(), 45), 23)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption={''}
              dateFormat="HH:mm"
              locale={da}
            />
            {endTime ? (
              <CloseIcon
                backgroundColor={'lightgray'}
                borderRadius="full"
                padding="3px"
                color={'white'}
                onClick={() => setEndTime(null)}
              />
            ) : (
              <></>
            )}
          </Box>
        </FormControl>
        <FormControl isRequired sx={styles.dateControl}>
          <FormLabel whiteSpace="nowrap">{'Date'}</FormLabel>
          <DatePicker
            withPortal={false}
            selected={startDate}
            calendarClassName="datepickerEditEventCalendar"
            onChange={onChangeStartDate}
            maxDate={addMonths(new Date(), 12)}
            dateFormat={'dd/MM/yyyy'}
          />
        </FormControl>
      </Flex>
      <FormControl sx={styles.recurringCheckbox} width="25%">
        <Checkbox isChecked={recurringChecked} onChange={(e) => onChangeRecurring(e)} />
        <FormLabel>Recurring</FormLabel>
      </FormControl>
      {recurringChecked && (
        <>
          <FormControl sx={styles.daysGroup}>
            <CheckboxGroup value={recurringDays} onChange={(e) => setRecurringDays(e)}>
              <Checkbox value="monday">Monday</Checkbox>
              <Checkbox value="tuesday">Tuesday</Checkbox>
              <Checkbox value="wednesday">Wednesday</Checkbox>
              <Checkbox value="thursday">Thursday</Checkbox>
              <Checkbox value="friday">Friday</Checkbox>
              <Checkbox value="saturday">Saturday</Checkbox>
              <Checkbox value="sunday">Sunday</Checkbox>
            </CheckboxGroup>
          </FormControl>
          <FormControl sx={{ ...styles.dateControl, marginY: '1rem' }} width="60%">
            <FormLabel>{'End date'}</FormLabel>
            <DatePicker
              withPortal={false}
              selected={endDate}
              calendarClassName="datepickerEditEventCalendar"
              onChange={(date) => setEndDate(date!)}
              minDate={addDays(startDate!, 1)}
              maxDate={addMonths(new Date(), 12)}
              dateFormat={'dd/MM/yyyy'}
            />
          </FormControl>
        </>
      )}
      <FormControl isInvalid={!!errors.imagePath} variant="floatingHostelCreator" isRequired>
        <Select
          defaultValue={1}
          {...register('imagePath', {
            required: 'Field required',
            onChange: (e) => setCurrentImagePath(e.target.value.split(' ')[0]),
          })}
        >
          <option key={`previous ${previousImage.split(' ')[1]}`} value={previousImage}>
            {/* ONG-3966 dont show previous field 
            {imagePaths?.find((image) => image.ItemUrl === currentImagePath)?.ItemName} */}
          </option>
          {imagePaths?.map((image) => (
            <option key={image.ItemId} value={`${image.ItemUrl} ${image.ItemId}`}>
              {image.ItemName}
            </option>
          ))}
        </Select>
        <FormLabel className="selectLabel">{'Choose image'}</FormLabel>
        <FormErrorMessage>{errors.imagePath?.message}</FormErrorMessage>
        {currentImagePath && (
          <Box sx={styles.imageBox}>
            <NextImage
              field={{ value: { src: `${process.env.NEXT_PUBLIC_SITECORE_API_HOST}${currentImagePath}` } }}
              fill={true}
              editable={true}
              style={{ objectFit: 'cover' }}
              unoptimized={true}
              imageParams={{ cw: 500, ch: 500, cx: 0.5, cy: 0.5 }}
            />
          </Box>
        )}
      </FormControl>
      <FormControl isInvalid={!!errors.eventColor} variant="floatingHostelCreator" isRequired>
        <Select
          {...register('eventColor', {
            required: 'Field required',
            onChange: (e) => setCurrentColor(e.target.value.split('|')[0]),
          })}
        >
          <option key={'previous'} value={`null|${eventToEdit.Event.en.CardColor}`}>
            {/* ONG-3966 dont show previous field 
            {eventColors.find((color) => color.ColorName === eventToEdit.Event.en.CardColor)?.ColorName ?? ' '} */}
          </option>
          {eventColors?.map((color) => (
            <option key={color.ColorId} value={`${color.ColorCode}|${color.ColorName}`}>
              {color.ColorName}
            </option>
          ))}
        </Select>
        <FormLabel className="selectLabel">{'Event Color'}</FormLabel>
        <FormErrorMessage>{errors.eventColor?.message}</FormErrorMessage>
        <Box
          sx={{ backgroundColor: currentColor ?? 'transparent', border: currentColor ? '1px solid #4E5356' : 'none' }}
          __css={styles.colorSpan}
        />
      </FormControl>
      <Flex gap={'5px'} direction={'row'}>
        <FormControl isInvalid={!!errors.title_en} variant="floatingHostelCreator" isRequired>
          <Input
            id="title_en"
            placeholder={'English title'}
            {...register('title_en', {
              required: 'Field required',
              maxLength: { value: 28, message: 'Max 28 chars allowed' },
              minLength: { value: 3, message: 'Title must be at least 3 chars long' },
              pattern: {
                //do not allow _ in title
                value: /^[^_]+$/,
                message: 'Title must not contain _',
              },
            })}
          />
          <FormLabel>{'English title'}</FormLabel>
          <FormErrorMessage>{errors.title_en?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.title_da} variant="floatingHostelCreator">
          <Input
            id="title_da"
            placeholder={'Dansk titel'}
            {...register('title_da', {
              maxLength: { value: 28, message: 'Max 28 chars allowed' },
              minLength: { value: 3, message: 'Title must be at least 3 chars long' },
              pattern: {
                //do not allow _ in title
                value: /^[^_]+$/,
                message: 'Title must not contain _',
              },
            })}
          />
          <FormLabel>{'Dansk titel'}</FormLabel>
          <FormErrorMessage>{errors.title_da?.message}</FormErrorMessage>
        </FormControl>
      </Flex>
      <Flex gap={'5px'} direction={'row'}>
        <FormControl isInvalid={!!errors.description_en} variant="floatingHostelCreator" isRequired>
          <Textarea
            id="description_en"
            rows={3}
            resize="none"
            defaultValue={''}
            placeholder={'English description'}
            {...register('description_en', {
              required: 'Field required',
              maxLength: { value: 65, message: 'Max 65 chars allowed' },
              minLength: { value: 3, message: 'Description must be at least 3 chars long' },
            })}
          />
          <FormLabel>{'English description'}</FormLabel>
          <FormErrorMessage>{errors.description_en?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.description_da} variant="floatingHostelCreator">
          <Textarea
            rows={3}
            resize="none"
            id="description_da"
            defaultValue={''}
            placeholder={'Dansk beskrivelse'}
            {...register('description_da', {
              maxLength: { value: 65, message: 'Max 65 chars allowed' },
              minLength: { value: 3, message: 'Description must be at least 3 chars long' },
            })}
          />
          <FormLabel>{'Dansk beskrivelse'}</FormLabel>
          <FormErrorMessage>{errors.description_da?.message}</FormErrorMessage>
        </FormControl>
      </Flex>
      <Flex gap={'5px'} direction={'row'}>
        <FormControl isInvalid={!!errors.location_en} variant="floatingHostelCreator" isRequired>
          <Input
            id="location_en"
            defaultValue={''}
            placeholder={'English location'}
            {...register('location_en', {
              required: 'Field required',
              maxLength: { value: 20, message: 'Max 20 chars allowed' },
              minLength: { value: 3, message: 'Location must be at least 3 chars long' },
            })}
          />
          <FormLabel>{'English location'}</FormLabel>
          <FormErrorMessage>{errors.location_en?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.location_da} variant="floatingHostelCreator">
          <Input
            id="location_da"
            defaultValue={''}
            placeholder={'Dansk lokation'}
            {...register('location_da', {
              maxLength: { value: 20, message: 'Max 20 chars allowed' },
              minLength: { value: 3, message: 'Location must be at least 3 chars long' },
            })}
          />
          <FormLabel>{'Dansk lokation'}</FormLabel>
          <FormErrorMessage>{errors.location_da?.message}</FormErrorMessage>
        </FormControl>
      </Flex>
      <Flex gap={'5px'} direction={'row'} marginBottom="2rem">
        <FormControl isInvalid={!!errors.link_en} variant="floatingHostelCreator">
          <Input
            id="link_en"
            defaultValue={''}
            placeholder={'English link'}
            {...register('link_en', {
              pattern: {
                value:
                  /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/,
                message: 'Enter a valid URL',
              },
            })}
          />
          <FormLabel>{'English link'}</FormLabel>
          <FormErrorMessage>{errors.link_en?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.link_da} variant="floatingHostelCreator">
          <Input
            id="link_da"
            defaultValue={''}
            placeholder={'Dansk link'}
            {...register('link_da', {
              pattern: {
                value:
                  /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/,
                message: 'Enter a valid URL',
              },
            })}
          />
          <FormLabel>{'Dansk link'}</FormLabel>
          <FormErrorMessage>{errors.link_da?.message}</FormErrorMessage>
        </FormControl>
      </Flex>
      <Flex __css={styles.textButtons}>
        <Text sx={styles.deleteLink} onClick={deleteEvent}>
          Remove event
        </Text>
        <Text sx={styles.copyLink} onClick={handleSubmit((data) => onSubmit(data, true))}>
          Copy event
        </Text>
      </Flex>
      {eventUpdated && <h3 style={{ color: 'green' }}>Event updated successfully!!</h3>}
      {eventDeleted && <h3 style={{ color: 'green' }}>Event deleted successfully!!</h3>}
      {eventCopied && <h3 style={{ color: 'green' }}>Event copied successfully!!</h3>}
      {hasError && <h3 style={{ color: 'red' }}>Something went wrong, please try again later</h3>}
      {(isSubmitting || isDeleting) && <Spinner ml={4} size="sm" />}
      <CButton
        text="Update"
        size="large"
        onClick={handleSubmit((data) => onSubmit(data, false))}
        textAlign="center"
        color="black"
        backgroundColor={hostelVariant === 'nex' ? '#00ffc4' : '#eaff00'}
        type="submit"
        editEventCalendar={true}
      />
    </form>
  );
};
export default HostelEventEdit;
