export type HostelEventCulture = {
  Title: string;
  Image: string;
  ImageId: string;
  Description: string;
  CardColor: string;
  CardColorCode: string;
  Link: string;
  StartTime: string;
  EndTime: string;
  Location: string;
};

export type HostelEvent = {
  Event: {
    da: HostelEventCulture;
    en: HostelEventCulture;
    recurringDays: (string | number)[];
    startDate: string;
    endDate: string;
  };
  ItemName: string;
  ItemId: string;
  HostelName: string;
};

export const TestEvent: HostelEvent = {
  Event: {
    recurringDays: ['monday', 'tuesday', 'wednesday', 'friday', 'saturday', 'sunday'],
    startDate: '2023-07-06T12:00:04.000Z',
    endDate: '2023-07-08T12:00:04.000Z',
    da: {
      Title: 'Title da',
      Image: '/-/media/project/eventimages/basic.jpg?rev=-1',
      ImageId: '{327249FB-5440-4BD2-85AE-0FC2C05FB793}',
      Description: 'DA DESCRIPTION',
      CardColor: 'lightGrey.200',
      CardColorCode: '#F2F2F2',
      Link: '',
      StartTime: '09:00',
      EndTime: '09:30',
      Location: '',
    },
    en: {
      Title: 'Title en',
      Image: '/-/media/project/eventimages/basic.jpg?rev=-1',
      ImageId: '{327249FB-5440-4BD2-85AE-0FC2C05FB793}',
      Description: 'Title new',
      CardColor: 'lightGrey.200',
      CardColorCode: '#F2F2F2',
      Link: 'http://google.com/',
      StartTime: '09:00',
      EndTime: '09:30',
      Location: 'Test location',
    },
  },
  ItemId: '{69E49676-A72E-4093-845C-55A2607F736D}',
  ItemName: 'Test event',
  HostelName: 'NEX',
};

export const getPreviousMonday = () => {
  const prevMonday = new Date();
  prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7));
  return prevMonday;
};

export const sitecoreStartIsoDate = (date: Date): string => {
  return date.toISOString().substring(0, 10) + 'T00:00:00.000Z';
};

export const sitecoreEndIsoDate = (date: Date): string => {
  return date.toISOString().substring(0, 10) + 'T23:59:59.000Z';
};

export type hostelEventsWeek = {
  Monday: Array<HostelEvent>;
  Tuesday: Array<HostelEvent>;
  Wednesday: Array<HostelEvent>;
  Thursday: Array<HostelEvent>;
  Friday: Array<HostelEvent>;
  Saturday: Array<HostelEvent>;
  Sunday: Array<HostelEvent>;
};

export const allowedColorsNex = [
  'Off-white',
  'NEX - Dark Grey',
  'NEX - Light Grey',
  'NEX - Light Green',
  'NEX - Purple',
  'NEX - Light Rosa',
  'NEX - Rosa',
];

export const allowedColorsSte = [
  'Off-white',
  'STE - Light Brown',
  'STE - Dark Brown',
  'STE - Light Grey',
  'STE - Dark Grey',
  'STE - Dark Green',
  'STE - Broken Orange',
];
