import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const LinkArrowRightIcon = (props: IconProps): ReactElement => {
  return (
    <Icon {...props} viewBox="0 0 30 30">
      <g fill="transparent">
        <circle cx="15" cy="15" r="14" />
        <line x1="22" y1="15" x2="7" y2="15" stroke="currentColor" />
        <path d="M18 20C18 17.2386 20.2386 15 23 15" stroke="currentColor" />
        <path d="M23 15C20.2386 15 18 12.7614 18 10" stroke="currentColor" />
      </g>
    </Icon>
  );
};
